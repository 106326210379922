import { useRef, useContext, useEffect, useState } from 'react';
import Moveable from 'react-moveable';
import {
  handleTransform,
  handleDragEnd,
  handleResizeEnd,
  handleRotateEnd,
} from '../../annotationUtils/handlers';
import { BarrierContext } from '../../context/BarrierContext';
import TextareaAutosize from 'react-textarea-autosize';
import { useFocusWithin } from 'ahooks';

export default function Text({ item }) {
  const { anno, setAnno } = useContext(BarrierContext);
  const targetRef = useRef();
  const [drag, setDrag] = useState(false);

  const isFocusWithin = useFocusWithin(() => document.getElementById('target'));

  useEffect(() => {
    if (!isFocusWithin) {
      setDrag(true);
    }
  }, [isFocusWithin]);

  return (
    <>
      <TextareaAutosize
        id='target'
        ref={targetRef}
        className='overflow-y-auto scrollbar-hide'
        style={{
          transform: handleTransform(item),
          position: 'absolute',
          left: 0,
          top: 0,
          width: `${item?.size?.width}px`,
          height: `${item?.size?.height}px`,
          resize: 'none',
          padding: '2px',
          backgroundColor: 'transparent',
          fontFamily: `${item?.fontFamily}`,
          fontSize: `${item?.fontSize}px`,
          fontWeight: `${item?.fontWeight}`,
          outline: 'none',
          color: `${item?.background?.color}`,
          zIndex: `${item?.zIndex}`,
        }}
        placeholder='Text'
        autoFocus={item.id === anno?.id ? true : false}
        onChange={(e) => {
          setAnno((prev) => ({
            ...item,
            content: e.target.value,
          }));
        }}
        value={item?.content}
        onClick={() => {
          targetRef?.current?.focus();
        }}
      />

      {item?.id === anno?.id && drag ? (
        <Moveable
          transform={handleTransform(item)}
          target={targetRef}
          draggable={true}
          dragFocusedInput={true}
          throttleDrag={1}
          rotatable={true}
          throttleRotate={0}
          rotationPosition={'top'}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          startDragRotate={0}
          throttleDragRotate={0}
          snappable={true}
          snapContainer={'.snapContainer'}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementGuidelines={[
            {
              element: '.snapContainer',
              className: 'red',
            },
          ]}
          bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          resizable={true}
          keepRatio={false}
          throttleResize={1}
          renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          snapRotationDegrees={[0, 45, 90, 135, 180, 225, 270, 315]}
          onRender={(e) => {
            e.target.style.cssText += e.cssText;
          }}
          onDragEnd={(e) => {
            const position = handleDragEnd(e);
            position && setAnno({ ...anno, position });
          }}
          onResizeEnd={(e) => {
            const size = handleResizeEnd(e);
            setAnno({ ...anno, size });
          }}
          onRotateEnd={(e) => {
            const rotation = handleRotateEnd(e);
            setAnno({ ...anno, rotation });
          }}
        />
      ) : null}
    </>
  );
}
