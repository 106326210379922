import { nanoid } from 'nanoid';

export const initialConfigFourCasings = {
  id: null,
  name: '',
  casingConfig: '4 casings',
  elements: [
    {
      id: nanoid(),
      name: 'crown valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'degraded',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'kill wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'fail',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'surface safety valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'lower master valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing valves',
      barrier: 'secondary',
      quantity: 0,
      status: 'pass',
      isDraggable: false,
    },

    {
      id: nanoid(),
      name: 'surface casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'intermediate casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'production casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isLocked: true,
      position: {
        x: 58,
        y: 236.5,
      },
      size: {
        width: '187px',
        height: '100px',
      },
      isResizable: {
        // top: true,
        bottom: true,
        // left: true,
        // right: true,
      },
      dragAxis: 'both',
      zIndex: 980,
    },
    {
      id: nanoid(),
      name: 'surface casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 78,
        y: 235.5,
      },
      size: {
        width: '147px',
        height: '180px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 985,
    },
    {
      id: nanoid(),
      name: 'intermediate casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 97.5,
        y: 207,
      },
      size: {
        width: '107.5px',
        height: '300px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 990,
    },
    {
      id: nanoid(),
      name: 'production casing (above packer)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 117,
        y: 179.3,
      },
      size: {
        width: '69px',
        height: '444px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 1001,
    },
    {
      id: nanoid(),
      name: 'production casing (below packer)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 117,
        y: 623,
      },
      size: {
        width: '69px',
        height: '200px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1002,
    },

    {
      id: nanoid(),
      name: 'casing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 20,
        y: 149,
      },
      size: {
        width: '260px',
        height: '90px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 200,
    },
    {
      id: nanoid(),
      name: 'tubing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 28,
        y: 139,
      },
      size: {
        width: '244px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 300,
    },

    // cement start

    {
      id: nanoid(),
      name: 'cement (conductor casing)',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 39,
        y: 270,
      },
      size: {
        width: '224px',
        height: '68px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 101,
    },

    {
      id: nanoid(),
      name: 'cement (surface casing)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 59.5,
        y: 265,
      },
      size: {
        width: '184px',
        height: '152px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 983,
    },

    {
      id: nanoid(),
      name: 'cement (intermediate casing)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 79.5,
        y: 361,
      },
      size: {
        width: '144px',
        height: '148px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 987,
    },

    {
      id: nanoid(),
      name: 'cement (production casing)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 98.5,
        y: 485,
      },
      size: {
        width: '105.5px',
        height: '339px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 992,
    },

    {
      id: nanoid(),
      name: 'production tubing (above dhsv)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 148,
      },
      size: {
        width: '30px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1110,
    },

    {
      id: nanoid(),
      name: 'production tubing (below dhsv)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 208,
      },
      size: {
        width: '30px',
        height: '480px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1200,
    },

    {
      id: nanoid(),
      name: 'downhole safety valve',
      barrier: 'primary',
      quantity: 1,
      status: 'fail',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131.5,
        y: 233,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1500,
    },

    {
      id: nanoid(),
      name: 'dhsv control line',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 165,
        y: 113,
      },
      size: {
        width: '70px',
        height: '141px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1501,
    },
    {
      id: nanoid(),
      name: 'safety valve landing nipple',
      barrier: 'primary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131,
        y: 204,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1503,
    },

    {
      id: nanoid(),
      name: 'svln control line',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 74,
        y: 113,
      },
      size: {
        width: '70px',
        height: '112px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1504,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67.5px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'bpv/twcv',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 142,
        y: 147,
      },
      size: {
        width: '18px',
        height: '34px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1508,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'caprock',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 318,
      },
      size: {
        width: '300px',
        height: '36px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 50,
    },

    {
      id: nanoid(),
      name: 'void area',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 76.5,
        y: 154,
      },
      size: {
        width: '150px',
        height: '16px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1510,
    },

    {
      id: nanoid(),
      name: 'in-situ formation',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 725,
      },
      size: {
        width: '300px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 51,
    },

    {
      id: nanoid(),
      name: 'sea level',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 250,
      },
      size: {
        width: '300px',
        height: '48px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 40,
      isAdditional: true,
    },
    {
      id: nanoid(),
      name: 'perforations',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 118,
        y: 746,
      },
      size: {
        width: '68px',
        height: '19px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1600,
      isAdditional: true,
    },

    //added up
  ],
  additionalElements: [
    // 5x plugs
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    // 5x packers

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    // 5x GLM

    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },

    // 5 X SSD

    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    // 5 x VR plug

    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
  ],
  annotations: [],
};

export const initialConfigThreeCasings = {
  id: null,
  name: '',
  casingConfig: '3 casings',
  elements: [
    {
      id: nanoid(),
      name: 'crown valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'degraded',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'kill wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'fail',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'surface safety valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'lower master valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing valves',
      barrier: 'secondary',
      quantity: 0,
      status: 'pass',
      isDraggable: false,
    },

    {
      id: nanoid(),
      name: 'surface casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'intermediate casing valves',
      barrier: 'secondary',
      quantity: 0,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'production casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isLocked: true,
      position: {
        x: 58,
        y: 208.5,
      },
      size: {
        width: '187px',
        height: '100px',
      },
      isResizable: {
        // top: true,
        bottom: true,
        // left: true,
        // right: true,
      },
      dragAxis: 'both',
      zIndex: 980,
    },
    {
      id: nanoid(),
      name: 'surface casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 84,
        y: 208.5,
      },
      size: {
        width: '134px',
        height: '220px',
      },
      isResizable: {
        top: true,
        bottom: true,
        left: true,
        right: true,
      },
      dragAxis: 'both',
      zIndex: 1000,
    },
    {
      id: nanoid(),
      name: 'intermediate casing',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 97.5,
        y: 207.5,
      },
      size: {
        width: '107.5px',
        height: '300px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 990,
    },
    {
      id: nanoid(),
      name: 'production casing (above packer)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 110,
        y: 179.5,
      },
      size: {
        width: '82px',
        height: '444px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 1002,
    },
    {
      id: nanoid(),
      name: 'production casing (below packer)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 110,
        y: 623,
      },
      size: {
        width: '82px',
        height: '200px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1002,
    },

    {
      id: nanoid(),
      name: 'casing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 20,
        y: 149,
      },
      size: {
        width: '260px',
        height: '90px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 200,
    },
    {
      id: nanoid(),
      name: 'tubing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 28,
        y: 139,
      },
      size: {
        width: '244px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 300,
    },

    // cement start

    {
      id: nanoid(),
      name: 'cement (conductor casing)',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 39,
        y: 243,
      },
      size: {
        width: '224px',
        height: '67px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 979,
    },

    {
      id: nanoid(),
      name: 'cement (surface casing)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 59.5,
        y: 265,
      },
      size: {
        width: '184px',
        height: '165px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 983,
    },

    {
      id: nanoid(),
      name: 'cement (intermediate casing)',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 79.5,
        y: 361,
      },
      size: {
        width: '143px',
        height: '148px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 987,
    },

    {
      id: nanoid(),
      name: 'cement (production casing)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 86,
        y: 398,
      },
      size: {
        width: '130px',
        height: '426px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 1001,
    },

    {
      id: nanoid(),
      name: 'production tubing (above dhsv)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 148,
      },
      size: {
        width: '30px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1110,
    },

    {
      id: nanoid(),
      name: 'production tubing (below dhsv)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 208,
      },
      size: {
        width: '30px',
        height: '480px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1200,
    },

    {
      id: nanoid(),
      name: 'downhole safety valve',
      barrier: 'primary',
      quantity: 1,
      status: 'fail',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131.5,
        y: 233,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1500,
    },

    {
      id: nanoid(),
      name: 'dhsv control line',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 165,
        y: 113,
      },
      size: {
        width: '70px',
        height: '141px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1501,
    },
    {
      id: nanoid(),
      name: 'safety valve landing nipple',
      barrier: 'primary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131,
        y: 204,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1503,
    },

    {
      id: nanoid(),
      name: 'svln control line',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 74,
        y: 113,
      },
      size: {
        width: '70px',
        height: '112px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1504,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 110,
        y: 620,
      },
      size: {
        width: '82px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'bpv/twcv',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 142,
        y: 147,
      },
      size: {
        width: '18px',
        height: '34px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1508,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'caprock',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 293,
      },
      size: {
        width: '300px',
        height: '36px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 50,
    },

    {
      id: nanoid(),
      name: 'void area',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 76.5,
        y: 154,
      },
      size: {
        width: '150px',
        height: '16px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1510,
    },

    {
      id: nanoid(),
      name: 'in-situ formation',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 725,
      },
      size: {
        width: '300px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 51,
    },

    {
      id: nanoid(),
      name: 'sea level',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 240,
      },
      size: {
        width: '300px',
        height: '48px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 40,
      isAdditional: true,
    },
    {
      id: nanoid(),
      name: 'perforations',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 110,
        y: 746,
      },
      size: {
        width: '82px',
        height: '19px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1600,
      isAdditional: true,
    },

    //added up
  ],
  additionalElements: [
    // 5x plugs
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    // 5x packers

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    // 5x GLM

    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },

    // 5 X SSD

    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    // 5 x VR plug

    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
  ],
  annotations: [],
};

export const initialConfigTwoCasings = {
  id: null,
  name: '',
  casingConfig: '2 casings',
  elements: [
    {
      id: nanoid(),
      name: 'crown valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'degraded',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'kill wing valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'fail',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'surface safety valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'lower master valve',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing valves',
      barrier: 'secondary',
      quantity: 0,
      status: 'pass',
      isDraggable: false,
    },

    {
      id: nanoid(),
      name: 'surface casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'intermediate casing valves',
      barrier: 'secondary',
      quantity: 0,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'production casing valves',
      barrier: 'secondary',
      quantity: 2,
      status: 'pass',
      isDraggable: false,
    },
    {
      id: nanoid(),
      name: 'conductor casing',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isDraggable: true,
      isLocked: true,
      position: {
        x: 58,
        y: 208.5,
      },
      size: {
        width: '187px',
        height: '100px',
      },
      isResizable: {
        // top: true,
        bottom: true,
        // left: true,
        // right: true,
      },
      dragAxis: 'both',
      zIndex: 980,
    },
    {
      id: nanoid(),
      name: 'surface casing',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 84,
        y: 208.5,
      },
      size: {
        width: '134px',
        height: '220px',
      },
      isResizable: {
        top: true,
        bottom: true,
        left: true,
        right: true,
      },
      dragAxis: 'both',
      zIndex: 1000,
    },
    {
      id: nanoid(),
      name: 'intermediate casing',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 97.5,
        y: 207.5,
      },
      size: {
        width: '107.5px',
        height: '300px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 990,
    },
    {
      id: nanoid(),
      name: 'production casing (above packer)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 110,
        y: 179.5,
      },
      size: {
        width: '82px',
        height: '444px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 1002,
    },
    {
      id: nanoid(),
      name: 'production casing (below packer)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 110,
        y: 623,
      },
      size: {
        width: '82px',
        height: '200px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1002,
    },

    {
      id: nanoid(),
      name: 'casing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 20,
        y: 149,
      },
      size: {
        width: '260px',
        height: '90px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 200,
    },
    {
      id: nanoid(),
      name: 'tubing hanger',
      barrier: 'secondary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 28,
        y: 139,
      },
      size: {
        width: '244px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 300,
    },

    // cement start

    {
      id: nanoid(),
      name: 'cement (conductor casing)',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 39,
        y: 270,
      },
      size: {
        width: '224px',
        height: '68px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 101,
    },

    {
      id: nanoid(),
      name: 'cement (surface casing)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 59.5,
        y: 208,
      },
      size: {
        width: '184px',
        height: '222px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 983,
    },

    {
      id: nanoid(),
      name: 'cement (intermediate casing)',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isDraggable: true,
      isMultiple: false,
      isLocked: true,
      position: {
        x: 79.5,
        y: 361,
      },
      size: {
        width: '143px',
        height: '148px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 987,
    },

    {
      id: nanoid(),
      name: 'cement (production casing)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 86,
        y: 398,
      },
      size: {
        width: '130px',
        height: '426px',
      },
      isResizable: {
        // left: true,
        // right: true,
        top: true,
        bottom: true,
      },
      dragAxis: 'both',
      zIndex: 1001,
    },

    {
      id: nanoid(),
      name: 'production tubing (above dhsv)',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 148,
      },
      size: {
        width: '30px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1110,
    },

    {
      id: nanoid(),
      name: 'production tubing (below dhsv)',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 136,
        y: 208,
      },
      size: {
        width: '30px',
        height: '480px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1200,
    },

    {
      id: nanoid(),
      name: 'downhole safety valve',
      barrier: 'primary',
      quantity: 1,
      status: 'fail',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131.5,
        y: 233,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1500,
    },

    {
      id: nanoid(),
      name: 'dhsv control line',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 165,
        y: 113,
      },
      size: {
        width: '70px',
        height: '141px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1501,
    },
    {
      id: nanoid(),
      name: 'safety valve landing nipple',
      barrier: 'primary',
      quantity: 1,
      status: 'pass',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 131,
        y: 204,
      },
      size: {
        width: '40px',
        height: '40px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1503,
    },

    {
      id: nanoid(),
      name: 'svln control line',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: true,
      position: {
        x: 74,
        y: 113,
      },
      size: {
        width: '70px',
        height: '112px',
      },
      isResizable: {
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 1504,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 110,
        y: 620,
      },
      size: {
        width: '82px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'bpv/twcv',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 142,
        y: 147,
      },
      size: {
        width: '18px',
        height: '34px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1508,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'caprock',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 410,
      },
      size: {
        width: '300px',
        height: '36px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 50,
    },

    {
      id: nanoid(),
      name: 'void area',
      barrier: 'secondary',
      quantity: 0,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 76.5,
        y: 154,
      },
      size: {
        width: '150px',
        height: '16px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1510,
    },

    {
      id: nanoid(),
      name: 'in-situ formation',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 725,
      },
      size: {
        width: '300px',
        height: '60px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 51,
    },

    {
      id: nanoid(),
      name: 'sea level',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 0,
        y: 240,
      },
      size: {
        width: '300px',
        height: '48px',
      },
      isResizable: {
        top: true,
        bottom: true,
      },
      dragAxis: 'y',
      zIndex: 40,
      isAdditional: true,
    },
    {
      id: nanoid(),
      name: 'perforations',
      barrier: 'none',
      quantity: 1,
      status: 'none',
      isVisible: true,
      isMultiple: false,
      isLocked: false,
      position: {
        x: 110,
        y: 746,
      },
      size: {
        width: '82px',
        height: '19px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1600,
      isAdditional: true,
    },

    //added up
  ],
  additionalElements: [
    // 5x plugs
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    {
      id: nanoid(),
      name: 'tubing plug',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 135.5,
        y: 550,
      },
      size: {
        width: '30px',
        height: '50px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1507,
    },
    // 5x packers

    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },
    {
      id: nanoid(),
      name: 'production packer',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isMultiple: true,
      isVisible: false,
      isLocked: false,
      position: {
        x: 117.5,
        y: 620,
      },
      size: {
        width: '67px',
        height: '15px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1005,
    },

    // 5x GLM

    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },
    {
      id: nanoid(),
      name: 'gas lift mandrel',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 165.5,
        y: 420,
      },
      size: {
        width: '6px',
        height: '28px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1505,
    },

    // 5 X SSD

    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },
    {
      id: nanoid(),
      name: 'sliding side door',
      barrier: 'primary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 133,
        y: 390,
      },
      size: {
        width: '36px',
        height: '20px',
      },
      isResizable: {},
      dragAxis: 'y',
      zIndex: 1506,
    },

    // 5 x VR plug

    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
    {
      id: nanoid(),
      name: 'vr plug',
      barrier: 'secondary',
      quantity: 1,
      status: 'none',
      isVisible: false,
      isMultiple: true,
      isLocked: false,
      position: {
        x: 54,
        y: 167,
      },
      size: {
        width: '32px',
        height: '12px',
      },
      isResizable: {},
      dragAxis: 'both',
      zIndex: 1509,
    },
  ],
  annotations: [],
};
