import { useRef, useContext } from 'react';
import Moveable from 'react-moveable';
import {
  handleTransform,
  handleDragEnd,
  handleResizeEnd,
  handleRotateEnd,
} from '../../annotationUtils/handlers';
import { BarrierContext } from '../../context/BarrierContext';

export default function Triangle({ item }) {
  const { anno, setAnno } = useContext(BarrierContext);
  const targetRef = useRef(null);

  const renderPattern = (type) => {
    switch (type) {
      case 'crossline':
        return (
          <line x1='0' y1='0' x2='0' y2='12' stroke='black' strokeWidth='1' />
        );
        break;
      case 'dash':
        return <image href='/patterns/dash.svg' />;
        break;
      case 'dot':
        return <image href='/patterns/dot.svg' />;
        break;

      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={{
          transform: handleTransform(item),
          position: 'absolute',
          left: 0,
          top: 0,
          width: `${item?.size?.width}px`,
          height: `${item?.size?.height}px`,
          zIndex: `${item?.zIndex}`,
        }}
        ref={targetRef}
      >
        <svg
          width={`${item?.size?.width}`}
          height={`${item?.size?.height}`}
          viewBox='0 0 52 45'
          xmlns='http://www.w3.org/2000/svg'
        >
          <defs>
            <pattern
              id='pattern'
              width='12'
              height='12'
              patternTransform={
                item?.background?.pattern === 'crossline'
                  ? `rotate(45 0 0)`
                  : `rotate(0 0 0)`
              }
              patternUnits='userSpaceOnUse'
            >
              {renderPattern(item?.background?.pattern)}
            </pattern>
          </defs>
          <path
            d='M0.885262 44.5L26 0.999999L51.1147 44.5H0.885262Z'
            stroke={`${item?.border?.color}`}
            strokeWidth={`${item?.border?.width}`}
            fill={
              item?.background?.pattern
                ? 'url(#pattern)'
                : item?.background?.color
            }
          />
        </svg>
      </div>

      {item?.id === anno?.id ? (
        <Moveable
          transform={handleTransform(item)}
          target={targetRef}
          draggable={true}
          throttleDrag={1}
          rotatable={true}
          throttleRotate={0}
          rotationPosition={'top'}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          startDragRotate={0}
          throttleDragRotate={0}
          snappable={true}
          snapContainer={'.snapContainer'}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementGuidelines={[
            {
              element: '.snapContainer',
              className: 'red',
            },
          ]}
          bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          resizable={true}
          keepRatio={true}
          throttleResize={1}
          renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          snapRotationDegrees={[0, 45, 90, 135, 180, 225, 270, 315]}
          onRender={(e) => {
            e.target.style.cssText += e.cssText;
          }}
          onDragEnd={(e) => {
            const position = handleDragEnd(e);
            position && setAnno({ ...anno, position });
          }}
          onResizeEnd={(e) => {
            const size = handleResizeEnd(e);
            setAnno({ ...anno, size });
          }}
          onRotateEnd={(e) => {
            const rotation = handleRotateEnd(e);
            setAnno({ ...anno, rotation });
          }}
        />
      ) : null}
    </>
  );
}
