import React, { useContext, forwardRef, useState, useEffect } from 'react';
import { Flex, IconButton, Button, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { BarrierContext } from '../context/BarrierContext';
import AnnotationList from './AnnotationList';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import DraggableElementList from './DraggableElementList';
import FourCasings from './diagrams/FourCasings';
import ThreeCasings from './diagrams/ThreeCasings';
import TwoCasings from './diagrams/TwoCasings';

const ConfigHistory = forwardRef((props, printRef) => {
  const { setComponent, setWell, config, setConfig, setAnno, configHistory } =
    useContext(BarrierContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    configHistory[currentIndex].schematicConfig.updatedAt = configHistory[currentIndex].date;
    setConfig(configHistory[currentIndex].schematicConfig);
  }, [currentIndex]);

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      elements: config?.elements,
    },
  });

  const handlePrev = () => {
    setCurrentIndex((prev) => prev - 1);
  };
  const handleNext = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const switchCasingConfig = (casingConfig) => {
    switch (casingConfig) {
      case '4 casings':
        return <FourCasings />;
        break;
      case '3 casings':
        return <ThreeCasings />;
        break;
      case '2 casings':
        return <TwoCasings />;
        break;
      default:
        return null;
    }
  };

  return (
    <form id='cdftForm' autoComplete='off'>
      <Flex w='640px' flexDir='column'>
        <Flex w='640px' justify='end' overflowY zIndex={40} mb={3}>
          <IconButton
            variant='outline'
            size='sm'
            aria-label='Close history'
            icon={<CloseIcon fontSize='10px' />}
            onClick={() => {
              setWell(null);
              setConfig(null);
              setComponent(null);
            }}
          />
        </Flex>
        <Flex w='full' justify='space-between' my={2}>
          <Button size='xs' onClick={handlePrev} isDisabled={currentIndex <= 0}>
            Prev
          </Button>
          <Button
            size='xs'
            onClick={handleNext}
            isDisabled={currentIndex >= configHistory.length - 1}
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <div ref={printRef} id='diagram'>
        <div
          // ref={drop}
          onClick={() => setAnno(null)}
          className='flex flex-col w-full h-[1040px] border overflow-y-auto scrollbar-hide'
        >
          <div className='p-2 border-b flex flex-col items-center'>
            <Text fontWeight='bold'>{config?.name}</Text>
            <Text fontSize='xs'>Configuration: {config?.name}</Text>
            <Text fontSize='xs'>
              Date: {moment(config?.updatedAt).format('DD/MM/YYYY')}
            </Text>
            <Text fontSize='xs'>{`Configuration ${currentIndex + 1} of ${
              configHistory?.length
            }`}</Text>
          </div>
          <div
            className='flex w-full h-full justify-center items-start'
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {/* diagram container */}

            <div
              className='flex w-[300px] h-full relative mr-[0px] mt-[36px]'
              // style={{
              //   pointerEvents: 'none',
              // }}
            >
              {switchCasingConfig(config?.casingConfig)}
              <DraggableElementList />
              <AnnotationList />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default ConfigHistory;
