export const arrowList = [
  {
    id: 1,
    svg: (
      <svg
        width='56'
        height='6'
        viewBox='0 0 56 6'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          id='Arrow 9'
          d='M0 3L5 5.88675V0.113249L0 3ZM4.5 3.5H56V2.5H4.5V3.5Z'
          fill='black'
        />
      </svg>
    ),
    arrowStyle: 'triangle single',
  },
  {
    id: 2,
    svg: (
      <svg
        width='56'
        height='6'
        viewBox='0 0 56 6'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          id='Arrow 10'
          d='M0 3L5 5.88675V0.113249L0 3ZM56 3L51 0.113249V5.88675L56 3ZM4.5 3.5H51.5V2.5H4.5V3.5Z'
          fill='black'
        />
      </svg>
    ),
    arrowStyle: 'triangle double',
  },
  {
    id: 3,
    svg: (
      <svg
        width='57'
        height='8'
        viewBox='0 0 57 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          id='Arrow 7'
          d='M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H57V3.5H1V4.5Z'
          fill='black'
        />
      </svg>
    ),
    arrowStyle: 'line single',
  },
  {
    id: 4,
    svg: (
      <svg
        width='58'
        height='8'
        viewBox='0 0 58 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          id='Arrow 8'
          d='M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM57.3536 4.35355C57.5488 4.15829 57.5488 3.84171 57.3536 3.64645L54.1716 0.464466C53.9763 0.269204 53.6597 0.269204 53.4645 0.464466C53.2692 0.659728 53.2692 0.976311 53.4645 1.17157L56.2929 4L53.4645 6.82843C53.2692 7.02369 53.2692 7.34027 53.4645 7.53553C53.6597 7.7308 53.9763 7.7308 54.1716 7.53553L57.3536 4.35355ZM1 4.5H57V3.5H1V4.5Z'
          fill='black'
        />
      </svg>
    ),
    arrowStyle: 'line double',
  },
];
