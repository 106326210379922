import { useContext, useState, useEffect } from 'react';
import {
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { BarrierContext } from '../context/BarrierContext';

export default function SelectWellDropdown() {
  const {
    handleUpdate,
    selectedWell,
  } = useContext(BarrierContext);
  const [showDropdown, setShowDropdown] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();


  return (
    <form>
      <Flex w='full' justify='end' mt={3}>
        <Button
          variant='solid'
          colorScheme='blue'
          size='sm'
          w='70px'
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Flex>
    </form>
  );
}
