import { nanoid } from 'nanoid';

export const getRectangle = (x = 0, y = 0) => {
  return {
    id: nanoid(),
    type: 'rectangle',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 60,
    },
    background: {
      color: 'transparent',
      pattern: null,
    },
    border: {
      color: '#000000',
      width: 1,
      radius: 0,
      style: 'solid',
    },
    zIndex: 2000,
  };
};

export const getCircle = (x = 0, y = 0) => {
  return {
    id: nanoid(),
    type: 'circle',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 60,
    },
    background: {
      color: 'transparent',
      pattern: null,
    },
    border: {
      color: '#000000',
      width: 1,
      radius: 50,
      style: 'solid',
    },
    zIndex: 2001,
  };
};

export const getEllipse = (x = 0, y = 0) => {
  return {
    id: nanoid(),
    type: 'ellipse',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 60,
    },
    background: {
      color: 'transparent',
      pattern: null,
    },
    border: {
      color: '#000000',
      width: 1,
      radius: 50,
      style: 'solid',
    },
    zIndex: 2002,
  };
};

export const getText = (x = 0, y = 0) => {
  return {
    id: nanoid(),
    type: 'text',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 16,
    },
    background: {
      color: '#000000',
    },
    color: '#000000',
    fontSize: 12,
    fontFamily: 'arial',
    fontWeight: 'normal',
    content: '',
    border: {
      style: 'solid',
    },
    zIndex: 2003,
  };
};

export const getLine = (x = 0, y = 80) => {
  return {
    id: nanoid(),
    type: 'line',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 80,
      height: 10,
    },
    background: {
      color: '#000000',
    },
    border: {
      width: 1,
      style: 'solid',
    },
    zIndex: 2004,
  };
};

export const getArrow = (x = 0, y = 80) => {
  return {
    id: nanoid(),
    type: 'arrow',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 10,
    },
    background: {
      color: '#000000',
    },
    border: {
      width: 1,
      style: 'solid',
    },
    zIndex: 2005,
    arrowStyle: 'triangle single',
  };
};

export const getTriangle = (x = 0, y = 0) => {
  return {
    id: nanoid(),
    type: 'triangle',
    position: {
      x,
      y,
    },
    rotation: 0,
    size: {
      width: 60,
      height: 60,
    },
    background: {
      color: 'transparent',
      pattern: null,
    },
    border: {
      color: '#000000',
      width: 1,
      radius: 0,
      style: 'solid',
    },
    zIndex: 2006,
  };
};

const getters = {
  text: getText,
  line: getLine,
  arrow: getArrow,
  rectangle: getRectangle,
  circle: getCircle,
  ellipse: getEllipse,
  triangle: getTriangle,
};

export const getRelevantAnnotation = (type, x, y) => {
  return getters[type](x, y);
};
