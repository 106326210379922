import { useContext, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { EditIcon, DeleteIcon, CopyIcon } from '@chakra-ui/icons';
import { IoMdCheckmark } from 'react-icons/io';
import { BarrierContext } from '../context/BarrierContext';
import DiagramSVGSmall from './diagrams/DiagramSVGSmall';

export default function ConfigItem({ item }) {
  const {
    config,
    setConfig,
    handleDelete,
    handleDuplicate,
    setComponent,
    setConfigSelected,
  } = useContext(BarrierContext);

  return (
    <div
      className={`relative max-w-[200px] h-24 border rounded flex justify-between p-1 space-x-1 my-1 hover:border-sky-600 ${
        item?.id === config?.id ? 'border-sky-600' : null
      }`}
      onDoubleClick={() => {
        setConfig(item);
        setTimeout(() => setComponent('diagram'), 400);
        setConfigSelected(true);
      }}
    >
      <div className='w-full h-full text-xs font-medium'>{item?.name}</div>
      <div className='w-full h-full  flex justify-center'>
        <DiagramSVGSmall />
      </div>

      {item?.id === config?.id ? (
        <div className='absolute bottom-1 right-2'>
          <IoMdCheckmark />
        </div>
      ) : null}

      <div className='h-full justify-start'>
        <Popup
          // ref={popupRef}
          position='bottom center'
          trigger={
            <div className='p-1 cursor-pointer'>
              <BsThreeDotsVertical />
            </div>
          }
        >
          <div className='bg-white border rounded-md cursor-pointer w-[100px]'>
            <div
              className='flex w-full items-center justify-between  hover:bg-[#EDF2F7] px-3 py-1'
              onClick={() => {
                setConfig(item);
                setTimeout(() => setComponent('diagram'), 400);
                setConfigSelected(true);
              }}
            >
              <div className='text-xs'>Edit</div>
              <EditIcon boxSize={3} />
            </div>
            <div
              className='flex w-full items-center justify-between hover:bg-[#EDF2F7] px-3 py-1'
              onClick={() => {
                handleDuplicate(item);
              }}
            >
              <div className='text-xs'>Copy</div>
              <CopyIcon boxSize={3} />
            </div>
            <div
              className='flex w-full items-center justify-between hover:bg-[#EDF2F7] px-3 py-1'
              onClick={() => {
                handleDelete(item?.id);
              }}
            >
              <div className='text-xs'>Delete</div>
              <DeleteIcon boxSize={3} />
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
