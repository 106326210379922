import { useContext } from 'react';
import { BarrierContext } from '../context/BarrierContext';
import Circle from './annotations/Circle';
import Rectangle from './annotations/Rectangle';
import Ellipse from './annotations/Ellipse';
import Triangle from './annotations/Triangle';
import Line from './annotations/Line';
import Text from './annotations/Text';
import Arrow from './annotations/Arrow';
import useContextMenu from '../hooks/useContextMenu';
import { nanoid } from 'nanoid';
import { DeleteIcon, CopyIcon } from '@chakra-ui/icons';

export default function AnnotationItem({ item }) {
  const {
    anno,
    setAnno,
    config,
    setConfig,
    accordionIndex,
    setAccordionIndex,
    annotations,
    setAnnotations,
  } = useContext(BarrierContext);
  const { clicked, setClicked } = useContextMenu();

  const switchAnno = (type) => {
    switch (type) {
      case 'text':
        return <Text item={item} />;
        break;
      case 'line':
        return <Line item={item} />;
        break;
      case 'arrow':
        return <Arrow item={item} />;
        break;
      case 'rectangle':
        return <Rectangle item={item} />;
        break;
      case 'circle':
        return <Circle item={item} />;
        break;
      case 'ellipse':
        return <Ellipse item={item} />;
        break;
      case 'triangle':
        return <Triangle item={item} />;
        break;
      default:
        return null;
    }
  };

  const handleCopyAnno = () => {
    if (anno) {
      const newAnno = {
        ...anno,
        id: nanoid(),
        position: {
          x: anno?.position?.x + 25,
          y: anno?.position?.y + 25,
        },
      };
      setConfig((prev) => ({
        ...prev,
        annotations: [...config?.annotations, newAnno],
      }));
    }
  };

  const handleDeleteAnno = () => {
    if (anno) {
      const updated = annotations?.filter((item) => item.id !== anno?.id);

      setConfig((prev) => ({
        ...prev,
        annotations: updated,
      }));
      setAnno(null);
    }
  };

  const ContextMenu = () => {
    return (
      <ul
        className='w-[86px] h-[56px] bg-slate-100 p-1 rounded-md z-[9999]'
        style={{
          position: 'absolute',
          top: `${anno?.position?.y + 30}px`,
          left: `${anno?.position?.x + 80}px`,
        }}
      >
        <li
          className='cursor-pointer hover:bg-slate-200 flex items-center justify-between'
          onClick={handleCopyAnno}
        >
          <p>Copy</p>
          <CopyIcon boxSize={3} />
        </li>
        <li
          className='cursor-pointer hover:bg-slate-200 flex items-center justify-between'
          onClick={handleDeleteAnno}
        >
          <p>Delete</p>
          <DeleteIcon boxSize={3} />
        </li>
      </ul>
    );
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (anno?.id === item.id) {
            return;
          }
          setAnno(item);
          setAccordionIndex(0);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          setClicked(true);
        }}
      >
        {switchAnno(item.type)}
      </div>
      {anno && clicked ? <ContextMenu /> : null}
    </>
  );
}
