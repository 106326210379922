import { useState, useEffect, useContext, useRef } from 'react';
import { BarrierContext } from '../context/BarrierContext';
import DraggableElementItem from './DraggableElementItem';

export default function DraggableElementList() {
  const { config } = useContext(BarrierContext);

  return (
    <>
      {config?.elements?.map((item, index) => (
        <DraggableElementItem key={item.id} item={item} />
      ))}

      {config?.additionalElements
        .filter((item) => item.isVisible)
        .map((item, index) => (
          <DraggableElementItem key={item.id} item={item} />
        ))}
    </>
  );
}
