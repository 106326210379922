import { useRef, useContext } from 'react';
import Moveable from 'react-moveable';
import {
  handleTransform,
  handleDragEnd,
  handleResizeEnd,
  handleRotateEnd,
} from '../../annotationUtils/handlers';
import { BarrierContext } from '../../context/BarrierContext';

export default function Arrow({ item }) {
  const { anno, setAnno } = useContext(BarrierContext);
  const targetRef = useRef(null);

  const renderArrow = (option) => {
    switch (option) {
      case 'triangle single':
        return (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: handleTransform(item),
              width: `${item?.size?.width}px`,
              height: `${item?.size?.height}px`,
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'space-between',
              zIndex: `${item?.zIndex}`,
            }}
            ref={targetRef}
          >
            <div
              style={{
                width: 0,
                height: 0,
                borderTop: '3px solid transparent',
                borderBottom: '3px solid transparent',
                borderRight: `8px solid ${item?.background?.color}`,
                zIndex: `${item?.zIndex}`,
              }}
            />
            <div
              style={{
                width: '100%',
                borderTop: `${item?.border?.width}px ${item?.border?.style} ${item?.background?.color}`,
              }}
            />
          </div>
        );
        break;
      case 'triangle double':
        return (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: handleTransform(item),
              width: `${item?.size?.width}px`,
              height: `${item?.size?.height}px`,
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'space-between',
            }}
            ref={targetRef}
          >
            <div
              style={{
                width: 0,
                height: 0,
                borderTop: '3px solid transparent',
                borderBottom: '3px solid transparent',
                borderRight: `8px solid ${item?.background?.color}`,
              }}
            />
            <div
              style={{
                width: '100%',
                borderTop: `${item?.border?.width}px ${item?.border?.style} ${item?.background?.color}`,
              }}
            />
            <div
              style={{
                width: 0,
                height: 0,
                borderTop: '3px solid transparent',
                borderBottom: '3px solid transparent',
                borderRight: `8px solid ${item?.background?.color}`,
                transform: 'rotate(180deg)',
              }}
            />
          </div>
        );
        break;
      case 'line single':
        return (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: handleTransform(item),
              width: `${item?.size?.width}px`,
              height: `${item?.size?.height}px`,
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'space-between',
            }}
            ref={targetRef}
          >
            <div
              style={{
                display: 'inline-block',
                width: '6px',
                height: '6px',
                borderTop: `1px solid ${item?.background?.color}`,
                borderLeft: `1px solid ${item?.background?.color}`,
                marginRight: '-6px',
                transform: 'rotate(-45deg)',
              }}
            />
            <div
              style={{
                width: '100%',
                borderTop: `${item?.border?.width}px ${item?.border?.style} ${item?.background?.color}`,
              }}
            />
          </div>
        );
        break;
      case 'line double':
        return (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: handleTransform(item),
              width: `${item?.size?.width}px`,
              height: `${item?.size?.height}px`,
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'space-between',
            }}
            ref={targetRef}
          >
            <div
              style={{
                display: 'inline-block',
                width: '6px',
                height: '6px',
                borderTop: `1px solid ${item?.background?.color}`,
                borderLeft: `1px solid ${item?.background?.color}`,
                marginRight: '-6px',
                transform: 'rotate(-45deg)',
              }}
            />
            <div
              style={{
                width: '100%',
                borderTop: `${item?.border?.width}px ${item?.border?.style} ${item?.background?.color}`,
              }}
            />
            <div
              style={{
                display: 'inline-block',
                width: '6px',
                height: '6px',
                borderTop: `1px solid ${item?.background?.color}`,
                borderLeft: `1px solid ${item?.background?.color}`,
                marginLeft: '-6px',
                transform: 'rotate(135deg)',
              }}
            />
          </div>
        );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {renderArrow(item?.arrowStyle)}

      {item?.id === anno?.id ? (
        <Moveable
          transform={handleTransform(item)}
          target={targetRef}
          draggable={true}
          throttleDrag={1}
          rotatable={true}
          throttleRotate={0}
          rotationPosition={'top'}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          startDragRotate={0}
          throttleDragRotate={0}
          snappable={true}
          snapContainer={'.snapContainer'}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementGuidelines={[
            {
              element: '.snapContainer',
              className: 'red',
            },
          ]}
          bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          resizable={true}
          keepRatio={false}
          throttleResize={1}
          renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          snapRotationDegrees={[0, 45, 90, 135, 180, 225, 270, 315]}
          onRender={(e) => {
            e.target.style.cssText += e.cssText;
          }}
          onDragEnd={(e) => {
            const position = handleDragEnd(e);
            position && setAnno({ ...anno, position });
          }}
          onResizeEnd={(e) => {
            const size = handleResizeEnd(e);
            setAnno({ ...anno, size });
          }}
          onRotateEnd={(e) => {
            const rotation = handleRotateEnd(e);
            setAnno({ ...anno, rotation });
          }}
        />
      ) : null}
    </>
  );
}
