import { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { BarrierContext } from '../../context/BarrierContext';

export default function FourCasings() {
  const { config, cdft, setCdftFill, setStroke } = useContext(BarrierContext);

  const cvQty = config?.elements[0].quantity;
  const wvQty = config?.elements[1].quantity;
  const kwvQty = config?.elements[2].quantity;
  const ssvQty = config?.elements[3].quantity;
  const lmvQty = config?.elements[4].quantity;
  const ccvQty = config?.elements[5].quantity;
  const scvQty = config?.elements[6].quantity;
  const icvQty = config?.elements[7].quantity;
  const pcvQty = config?.elements[8].quantity;
  const tubingHangerQty = config?.elements[15].quantity;
  const casingHangerQty = config?.elements[14].quantity;

  return (
    <div className=' ml-[3px]'>
      <svg
        width='298'
        height='273'
        viewBox='0 0 298 273'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='4-casings'>
          {casingHangerQty ? (
            <Tooltip
              label='CASING HANGER'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='left'
            >
              <g id='casing-hanger-all'>
                <g id='casing-hanger-surface'>
                  <g id='surface-casing-entry'>
                    <path
                      id='Line 144'
                      d='M57 236L240 236'
                      stroke={setStroke('casing hanger')}
                    />
                    {scvQty === 4 ? null : (
                      <rect
                        id='Rectangle 68'
                        x='239.747'
                        y='222'
                        width='4.0125'
                        height='14'
                        fill='black'
                      />
                    )}
                  </g>
                  <g id='casing-hanger-seal'>
                    <g id='surface-casing-seal-left'>
                      <g id='Group 6'>
                        <rect
                          id='Rectangle 19'
                          x='57.2296'
                          y='208'
                          width='13.9923'
                          height='14'
                          stroke={cdft ? '#000000' : setStroke('casing hanger')}
                          fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                        />
                        <path
                          id='Line 95'
                          d='M57.6412 221.748L71.222 208'
                          stroke={cdft ? '#000000' : setStroke('casing hanger')}
                        />
                        <path
                          id='Line 96'
                          d='M70.8104 221.611L57.4692 208.454'
                          stroke={cdft ? '#000000' : setStroke('casing hanger')}
                        />
                      </g>

                      <g id='surface-casing-seal-right'>
                        <g id='Group 6_2'>
                          <rect
                            id='Rectangle 19_2'
                            width='13.9923'
                            height='14'
                            transform='matrix(-1 0 0 1 239.433 208)'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                            fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                          />
                          <path
                            id='Line 95_2'
                            d='M239.021 221.748L225.44 208'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                          <path
                            id='Line 96_2'
                            d='M225.852 221.611L239.193 208.454'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id='casing-hanger-intermediate'>
                  <g id='production-casing-entry_2'>
                    <path
                      id='Line 144_2'
                      d='M57 208L240 208'
                      stroke={setStroke('casing hanger')}
                    />
                    {icvQty === 4 ? null : (
                      <rect
                        id='Rectangle 68_2'
                        x='239.747'
                        y='194'
                        width='4.0125'
                        height='14'
                        fill='black'
                      />
                    )}
                  </g>
                  <g id='casing-hanger-seal_2'>
                    <g id='intermediate-casing-seal_2'>
                      <g id='production-casing-seal-left_2'>
                        <g id='Group 6_3'>
                          <rect
                            id='Rectangle 19_3'
                            x='57.2296'
                            y='180'
                            width='13.9923'
                            height='14'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                            fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                          />
                          <path
                            id='Line 95_3'
                            d='M57.6412 193.748L71.222 180'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                          <path
                            id='Line 96_3'
                            d='M70.8104 193.611L57.4692 180.454'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                        </g>
                      </g>
                      <g id='intermediate-casing-seal-right_2'>
                        <g id='Group 6_4'>
                          <rect
                            id='Rectangle 19_4'
                            width='13.9923'
                            height='14'
                            transform='matrix(-1 0 0 1 239.433 180)'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                            fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                          />
                          <path
                            id='Line 95_4'
                            d='M239.021 193.748L225.44 180'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                          <path
                            id='Line 96_4'
                            d='M225.852 193.611L239.193 180.454'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id='casing-hanger-production'>
                  <g id='production-casing-entry_3'>
                    <path
                      id='Line 144_3'
                      d='M57 180L240 180'
                      stroke={setStroke('casing hanger')}
                    />
                    {pcvQty === 4 ? null : (
                      <rect
                        id='Rectangle 68_3'
                        x='239.747'
                        y='166'
                        width='4.0125'
                        height='14'
                        fill='black'
                      />
                    )}
                  </g>
                  <g id='casing-hanger-seal_3'>
                    <g id='intermediate-casing-seal_3'>
                      <g id='production-casing-seal-left_3'>
                        <g id='Group 6_5'>
                          <rect
                            id='Rectangle 19_5'
                            x='57.2296'
                            y='152'
                            width='13.9923'
                            height='14'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                            fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                          />
                          <path
                            id='Line 95_5'
                            d='M57.6412 165.748L71.222 152'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                          <path
                            id='Line 96_5'
                            d='M70.8104 165.611L57.4692 152.454'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                        </g>
                      </g>
                      <g id='production-casing-seal-right_3'>
                        <g id='Group 6_6'>
                          <rect
                            id='Rectangle 19_6'
                            width='13.9923'
                            height='14'
                            transform='matrix(-1 0 0 1 239.433 152)'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                            fill={cdft ? setCdftFill('casing hanger') : '#FFFFFF'}
                          />
                          <path
                            id='Line 95_6'
                            d='M239.021 165.748L225.44 152'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                          <path
                            id='Line 96_6'
                            d='M225.852 165.611L239.193 152.454'
                            stroke={
                              cdft ? '#000000' : setStroke('casing hanger')
                            }
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </Tooltip>
          ) : null}

          {ccvQty ? (
            <Tooltip
              label='CONDUCTOR CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='conductor-casing-valves-left'>
                <path
                  id='Line 146_3'
                  d='M18.0563 250L57.1782 250'
                  stroke={
                    cdft ? '#000000' : setStroke('conductor casing valves')
                  }
                />
                <path
                  id='Line 147_3'
                  d='M18.0563 264L57.1782 264'
                  stroke={
                    cdft ? '#000000' : setStroke('conductor casing valves')
                  }
                />
                <g id='production-casing-valve_5'>
                  <ellipse
                    id='Ellipse 1_5'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 26.0813 257.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                    fill={cdft ? setCdftFill('CCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_5'
                    d='M33.9966 249.732L49.4527 264.83'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                  <path
                    id='Vector 7_5'
                    d='M33.9528 264.909L49.4082 249.812'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_6'>
                  <ellipse
                    id='Ellipse 1_6'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 0 257.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                    fill={cdft ? setCdftFill('CCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_6'
                    d='M7.91528 249.732L23.3714 264.83'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                  <path
                    id='Vector 7_6'
                    d='M7.87146 264.909L23.3269 249.812'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {ccvQty && ccvQty === 4 ? (
            <Tooltip
              label='CONDUCTOR CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='conductor-casing-valves-right'>
                <path
                  id='Line 146_4'
                  d='M279.187 250L240.065 250'
                  stroke={
                    cdft ? '#000000' : setStroke('conductor casing valves')
                  }
                />
                <path
                  id='Line 147_4'
                  d='M279.187 264L240.065 264'
                  stroke={
                    cdft ? '#000000' : setStroke('conductor casing valves')
                  }
                />
                <g id='production-casing-valve_7'>
                  <ellipse
                    id='Ellipse 1_7'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 271.162 257.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                    fill={cdft ? setCdftFill('CCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_7'
                    d='M263.247 249.732L247.791 264.83'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                  <path
                    id='Vector 7_7'
                    d='M263.291 264.909L247.835 249.812'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_8'>
                  <ellipse
                    id='Ellipse 1_8'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 297.243 257.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                    fill={cdft ? setCdftFill('CCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_8'
                    d='M289.328 249.732L273.872 264.83'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                  <path
                    id='Vector 7_8'
                    d='M289.372 264.909L273.917 249.812'
                    stroke={
                      cdft ? '#000000' : setStroke('conductor casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {scvQty ? (
            <Tooltip
              label='SURFACE CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='surface-casing-valves-left'>
                <path
                  id='Line 146'
                  d='M18.0563 222L57.1782 222'
                  stroke={cdft ? '#000000' : setStroke('surface casing valves')}
                />
                <path
                  id='Line 147'
                  d='M18.0563 236L57.1782 236'
                  stroke={cdft ? '#000000' : setStroke('surface casing valves')}
                />
                <g id='production-casing-valve'>
                  <ellipse
                    id='Ellipse 1'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 26.0813 229.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                    fill={cdft ? setCdftFill('SCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6'
                    d='M33.9966 221.732L49.4527 236.83'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                  <path
                    id='Vector 7'
                    d='M33.9528 236.909L49.4082 221.812'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_2'>
                  <ellipse
                    id='Ellipse 1_2'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 0 229.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                    fill={cdft ? setCdftFill('SCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_2'
                    d='M7.91528 221.732L23.3714 236.83'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                  <path
                    id='Vector 7_2'
                    d='M7.87146 236.909L23.3269 221.812'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {scvQty && scvQty === 4 ? (
            <Tooltip
              label='SURFACE CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='surface-casing-valves-right'>
                <path
                  id='Line 146_2'
                  d='M278.187 222L239.065 222'
                  stroke={cdft ? '#000000' : setStroke('surface casing valves')}
                />
                <path
                  id='Line 147_2'
                  d='M278.187 236L239.065 236'
                  stroke={cdft ? '#000000' : setStroke('surface casing valves')}
                />
                <g id='production-casing-valve_3'>
                  <ellipse
                    id='Ellipse 1_3'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 270.162 229.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                    fill={cdft ? setCdftFill('SCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_3'
                    d='M262.247 221.732L246.791 236.83'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                  <path
                    id='Vector 7_3'
                    d='M262.291 236.909L246.835 221.812'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_4'>
                  <ellipse
                    id='Ellipse 1_4'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 296.243 229.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                    fill={cdft ? setCdftFill('SCV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_4'
                    d='M288.328 221.732L272.872 236.83'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                  <path
                    id='Vector 7_4'
                    d='M288.372 236.909L272.917 221.812'
                    stroke={
                      cdft ? '#000000' : setStroke('surface casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {icvQty ? (
            <Tooltip
              label='INTERMEDIATE CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='intermediate-casing-valves-left'>
                <path
                  id='Line 146_5'
                  d='M18.0563 194L57.1782 194'
                  stroke={
                    cdft ? '#000000' : setStroke('intermediate casing valves')
                  }
                />
                <path
                  id='Line 147_5'
                  d='M18.0563 208L57.1782 208'
                  stroke={
                    cdft ? '#000000' : setStroke('intermediate casing valves')
                  }
                />
                <g id='production-casing-valve_9'>
                  <ellipse
                    id='Ellipse 1_9'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 26.0813 201.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('ICV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_9'
                    d='M33.9966 193.732L49.4527 208.83'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                  <path
                    id='Vector 7_9'
                    d='M33.9528 208.909L49.4082 193.812'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_10'>
                  <ellipse
                    id='Ellipse 1_10'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 0 201.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('ICV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_10'
                    d='M7.91528 193.732L23.3714 208.83'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                  <path
                    id='Vector 7_10'
                    d='M7.87146 208.909L23.3269 193.812'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}
          {icvQty && icvQty === 4 ? (
            <Tooltip
              label='INTERMEDIATE CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='intermediate-casing-valves-right'>
                <path
                  id='Line 146_6'
                  d='M278.187 194L239.065 194'
                  stroke={
                    cdft ? '#000000' : setStroke('intermediate casing valves')
                  }
                />
                <path
                  id='Line 147_6'
                  d='M278.187 208L239.065 208'
                  stroke={
                    cdft ? '#000000' : setStroke('intermediate casing valves')
                  }
                />
                <g id='production-casing-valve_11'>
                  <ellipse
                    id='Ellipse 1_11'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 270.162 201.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('ICV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_11'
                    d='M262.247 193.732L246.791 208.83'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                  <path
                    id='Vector 7_11'
                    d='M262.291 208.909L246.835 193.812'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_12'>
                  <ellipse
                    id='Ellipse 1_12'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 296.243 201.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('ICV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_12'
                    d='M288.328 193.732L272.872 208.83'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                  <path
                    id='Vector 7_12'
                    d='M288.372 208.909L272.917 193.812'
                    stroke={
                      cdft ? '#000000' : setStroke('intermediate casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {pcvQty ? (
            <Tooltip
              label='PRODUCTION CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='production-casing-valves-left'>
                <path
                  id='Line 146_7'
                  d='M18.0563 166L57.1782 166'
                  stroke={
                    cdft ? '#000000' : setStroke('production casing valves')
                  }
                />
                <path
                  id='Line 147_7'
                  d='M18.0563 180L57.1782 180'
                  stroke={
                    cdft ? '#000000' : setStroke('production casing valves')
                  }
                />
                <g id='production-casing-valve_13'>
                  <ellipse
                    id='Ellipse 1_13'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 26.0813 173.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('PCV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_13'
                    d='M33.9966 165.732L49.4527 180.83'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                  <path
                    id='Vector 7_13'
                    d='M33.9528 180.909L49.4082 165.812'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_14'>
                  <ellipse
                    id='Ellipse 1_14'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 0 173.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('PCV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_14'
                    d='M7.91528 165.732L23.3714 180.83'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                  <path
                    id='Vector 7_14'
                    d='M7.87146 180.909L23.3269 165.812'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {pcvQty && pcvQty === 4 ? (
            <Tooltip
              label='PRODUCTION CASING VALVES'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='bottom'
            >
              <g id='production-casing-valves-right'>
                <path
                  id='Line 146_8'
                  d='M278.187 166L239.065 166'
                  stroke={
                    cdft ? '#000000' : setStroke('production casing valves')
                  }
                />
                <path
                  id='Line 147_8'
                  d='M278.187 180L239.065 180'
                  stroke={
                    cdft ? '#000000' : setStroke('production casing valves')
                  }
                />
                <g id='production-casing-valve_15'>
                  <ellipse
                    id='Ellipse 1_15'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 270.162 173.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('PCV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_15'
                    d='M262.247 165.732L246.791 180.83'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                  <path
                    id='Vector 7_15'
                    d='M262.291 180.909L246.835 165.812'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                </g>
                <g id='production-casing-valve_16'>
                  <ellipse
                    id='Ellipse 1_16'
                    cx='10.8906'
                    cy='10.8292'
                    rx='10.8906'
                    ry='10.8292'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 296.243 173.22)'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                    fill={
                      cdft ? setCdftFill('PCV') : '#FFFFFF'
                    }
                  />
                  <path
                    id='Vector 6_16'
                    d='M288.328 165.732L272.872 180.83'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                  <path
                    id='Vector 7_16'
                    d='M288.372 180.909L272.917 165.812'
                    stroke={
                      cdft ? '#000000' : setStroke('production casing valves')
                    }
                  />
                </g>
              </g>
            </Tooltip>
          ) : null}

          {tubingHangerQty ? (
            <Tooltip
              label='TUBING HANGER'
              bg='gray.100'
              color='black'
              fontSize='8px'
              placement='left'
            >
              <g id='tubing-hanger'>
                <g id='tubing-header-left'>
                  <ellipse
                    id='Ellipse 1_17'
                    cx='7.00107'
                    cy='6.9616'
                    rx='7.00107'
                    ry='6.9616'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 29.0907 148.784)'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                    fill={cdft ? setCdftFill('tubing hanger') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_17'
                    d='M34.1792 143.971L44.1153 153.676'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                  />
                  <path
                    id='Vector 7_17'
                    d='M34.1509 153.727L44.0865 144.022'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                  />
                </g>
                <g id='tubing-header-right'>
                  <ellipse
                    id='Ellipse 1_18'
                    cx='7.00107'
                    cy='6.9616'
                    rx='7.00107'
                    ry='6.9616'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 247.209 148.784)'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                    fill={cdft ? setCdftFill('tubing hanger') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_18'
                    d='M252.297 143.971L262.234 153.676'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                  />
                  <path
                    id='Vector 7_18'
                    d='M252.269 153.727L262.205 144.022'
                    stroke={cdft ? '#000000' : setStroke('tubing hanger')}
                  />
                </g>
                <path
                  id='Vector 41'
                  d='M46.1438 149L249.778 149'
                  stroke={setStroke('tubing hanger')}
                />
              </g>
            </Tooltip>
          ) : null}

          <g id='xmas-tree'>
            <g id='tree-cap'>
              <rect
                id='Rectangle 1'
                x='127.181'
                y='1'
                width='42.402'
                height='13.3133'
                rx='3'
                stroke='black'
              />
              <g id='Group 36'>
                <path id='Vector 1' d='M132.15 1V14.1702' stroke='black' />
                <path id='Vector 2' d='M140.6 1V14.1702' stroke='black' />
                <path id='Vector 3' d='M148.625 1V14.1702' stroke='black' />
                <path id='Vector 4' d='M156.65 1V14.1702' stroke='black' />
                <path id='Vector 5' d='M164.675 1V14.1702' stroke='black' />
              </g>
            </g>

            {cvQty ? (
              <Tooltip
                label='CROWN VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='bottom'
              >
                <g id='cv'>
                  <ellipse
                    id='Ellipse 1_19'
                    cx='14.0021'
                    cy='13.9232'
                    rx='14.0021'
                    ry='13.9232'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 128.246 31.4146)'
                    stroke={cdft ? '#000000' : setStroke('crown valve')}
                    fill={cdft ? setCdftFill('CV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_19'
                    d='M138.423 21.7872L158.295 41.1988'
                    stroke={cdft ? '#000000' : setStroke('crown valve')}
                  />
                  <path
                    id='Vector 7_19'
                    d='M138.367 41.3006L158.238 21.8899'
                    stroke={cdft ? '#000000' : setStroke('crown valve')}
                  />
                </g>
              </Tooltip>
            ) : null}
            {ssvQty ? (
              <Tooltip
                label='SURFACE SAFETY VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='bottom'
              >
                <g id='ssv'>
                  <ellipse
                    id='Ellipse 1_20'
                    cx='14.0021'
                    cy='13.9658'
                    rx='14.0021'
                    ry='13.9658'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 128.246 94.1669)'
                    stroke={
                      cdft ? '#000000' : setStroke('surface safety valve')
                    }
                    fill={cdft ? setCdftFill('SSV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_20'
                    d='M138.424 84.54L158.042 104.198'
                    stroke={
                      cdft ? '#000000' : setStroke('surface safety valve')
                    }
                  />
                  <path
                    id='Vector 7_20'
                    d='M138.398 104.083L158.269 84.6724'
                    stroke={
                      cdft ? '#000000' : setStroke('surface safety valve')
                    }
                  />
                </g>
              </Tooltip>
            ) : null}

            {lmvQty ? (
              <Tooltip
                label='LOWER MASTER VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='bottom'
              >
                <g id='lmv'>
                  <ellipse
                    id='Ellipse 1_21'
                    cx='14.0021'
                    cy='14.0021'
                    rx='14.0021'
                    ry='14.0021'
                    transform='matrix(0.715348 -0.698768 0.715348 0.698768 128.246 132.128)'
                    stroke={cdft ? '#000000' : setStroke('lower master valve')}
                    fill={cdft ? setCdftFill('LMV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_21'
                    d='M138.424 122.502L158.099 141.721'
                    stroke={cdft ? '#000000' : setStroke('lower master valve')}
                  />
                  <path
                    id='Vector 7_21'
                    d='M138.424 142.07L158.295 122.66'
                    stroke={cdft ? '#000000' : setStroke('lower master valve')}
                  />
                </g>
              </Tooltip>
            ) : null}

            {wvQty ? (
              <Tooltip
                label='WING VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='bottom'
              >
                <g id='wv'>
                  <circle
                    id='Ellipse 1_22'
                    cx='14.0021'
                    cy='14.0021'
                    r='14.0021'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 139.416 60.8539)'
                    stroke={cdft ? '#000000' : setStroke('wing valve')}
                    fill={cdft ? setCdftFill('WV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_22'
                    d='M129.238 51.2273L109.563 70.4464'
                    stroke={cdft ? '#000000' : setStroke('wing valve')}
                  />
                  <path
                    id='Vector 7_22'
                    d='M129.238 70.7958L109.367 51.3851'
                    stroke={cdft ? '#000000' : setStroke('wing valve')}
                  />
                </g>
              </Tooltip>
            ) : null}

            {kwvQty ? (
              <Tooltip
                label='KILL WING VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='bottom'
              >
                <g id='kwv'>
                  <circle
                    id='Ellipse 1_23'
                    cx='14.0021'
                    cy='14.0021'
                    r='14.0021'
                    transform='matrix(-0.715348 -0.698768 -0.715348 0.698768 196.427 60.8539)'
                    stroke={cdft ? '#000000' : setStroke('kill wing valve')}
                    fill={cdft ? setCdftFill('KWV') : '#FFFFFF'}
                  />
                  <path
                    id='Vector 6_23'
                    d='M186.248 51.2274L166.573 70.4464'
                    stroke={cdft ? '#000000' : setStroke('kill wing valve')}
                  />
                  <path
                    id='Vector 7_23'
                    d='M186.248 70.7959L166.377 51.3852'
                    stroke={cdft ? '#000000' : setStroke('kill wing valve')}
                  />
                </g>
              </Tooltip>
            ) : null}

            <g id='wv-arrows'>
              <path
                id='Arrow 1'
                d='M80.6072 60.6456L85.6072 63.5324L85.6072 57.7589L80.6072 60.6456ZM97.7885 60.1456L85.1072 60.1456L85.1072 61.1456L97.7885 61.1456L97.7885 60.1456Z'
                fill='black'
              />
              <path
                id='Arrow 3'
                d='M80.6072 69.6591L85.6072 72.5459L85.6072 66.7724L80.6072 69.6591ZM97.7885 69.1591L85.1072 69.1591L85.1072 70.1591L97.7885 70.1591L97.7885 69.1591Z'
                fill='black'
              />
              <path
                id='Arrow 2'
                d='M80.6072 51.1315L85.6072 54.0182L85.6072 48.2447L80.6072 51.1315ZM97.7885 50.6315L85.1072 50.6315L85.1072 51.6315L97.7885 51.6315L97.7885 50.6315Z'
                fill='black'
              />
            </g>
            <g id='tree-body'>
              <g id='tree-body_2'>
                <path
                  id='Vector 9'
                  d='M133.713 46.5653V18.6754H116.176L105.598 28.8387V46.5653H133.713Z'
                  stroke='black'
                />
                <path
                  id='Vector 29'
                  d='M163 47V19H180.465L191 29.2034V47H163Z'
                  stroke='black'
                />
                <path
                  id='Vector 10'
                  d='M133.743 18.8545V14.1702'
                  stroke='black'
                />
                <path
                  id='Vector 30'
                  d='M162.609 18.8545V14.1702'
                  stroke='black'
                />
                <g id='kwv-flange'>
                  <rect
                    id='Rectangle 3'
                    x='191.726'
                    y='41.2854'
                    width='7.57178'
                    height='39.4468'
                    stroke='black'
                  />
                  <rect
                    id='Rectangle 5'
                    x='199.298'
                    y='45.7231'
                    width='2.01914'
                    height='2.95851'
                    stroke='black'
                  />
                  <rect
                    id='Rectangle 6'
                    x='199.298'
                    y='73.8291'
                    width='2.01914'
                    height='2.95851'
                    stroke='black'
                  />
                  <rect
                    id='Rectangle 7'
                    x='199.298'
                    y='52.6264'
                    width='2.01914'
                    height='2.95851'
                    stroke='black'
                  />
                  <rect
                    id='Rectangle 8'
                    x='199.298'
                    y='60.0227'
                    width='2.01914'
                    height='2.95851'
                    stroke='black'
                  />
                  <rect
                    id='Rectangle 9'
                    x='199.298'
                    y='66.4327'
                    width='2.01914'
                    height='2.95851'
                    stroke='black'
                  />
                </g>
                <path
                  id='Vector 70'
                  d='M105.989 74.7604H80.6072'
                  stroke='black'
                />
                <path
                  id='Vector 71'
                  d='M105.989 46.7084H80.6072'
                  stroke='black'
                />
              </g>
              <path
                id='Vector 69'
                d='M57.1782 152V148.873L105.511 127.376V75H133.35V149'
                stroke='black'
              />
              <path
                id='Vector 70_2'
                d='M240 152V148.873L191.142 127.376V75H177.071H163V148.873'
                stroke='black'
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
