import { useRef, useContext } from 'react';
import Moveable from 'react-moveable';
import {
  handleTransform,
  handleDragEnd,
  handleResizeEnd,
  handleRotateEnd,
} from '../../annotationUtils/handlers';
import { BarrierContext } from '../../context/BarrierContext';
import DotPattern from '../patterns/dot.svg';
import DashPattern from '../patterns/dash.svg';
import CrosslinePattern from '../patterns/crossline.svg';

export default function Ellipse({ item }) {
  const { anno, setAnno } = useContext(BarrierContext);
  const targetRef = useRef(null);

  const renderPattern = (type) => {
    switch (type) {
      case 'crossline':
        return `url(${CrosslinePattern})`;
        break;
      case 'dot':
        return `url(${DotPattern})`;
        break;
      case 'dash':
        return `url(${DashPattern})`;
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={{
          transform: handleTransform(item),
          position: 'absolute',
          left: 0,
          top: 0,
          width: `${item?.size?.width}px`,
          height: `${item?.size?.height}px`,
          backgroundColor: `${item?.background?.color}`,
          backgroundImage: renderPattern(`${item?.background?.pattern}`),
          backgroundRepeat: 'repeat',
          borderWidth: `${item?.border?.width}px`,
          borderColor: `${item?.border?.color}`,
          borderRadius: `${item?.border?.radius}%`,
          borderStyle: `${item?.border?.style}`,
          zIndex: `${item?.zIndex}`,
        }}
        ref={targetRef}
      />

      {item?.id === anno?.id ? (
        <Moveable
          transform={handleTransform(item)}
          target={targetRef}
          draggable={true}
          throttleDrag={1}
          rotatable={true}
          throttleRotate={0}
          rotationPosition={'top'}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          startDragRotate={0}
          throttleDragRotate={0}
          snappable={true}
          snapContainer={'.snapContainer'}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementGuidelines={[
            {
              element: '.snapContainer',
              className: 'red',
            },
          ]}
          bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          resizable={true}
          keepRatio={false}
          throttleResize={1}
          renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          snapRotationDegrees={[0, 45, 90, 135, 180, 225, 270, 315]}
          onRender={(e) => {
            e.target.style.cssText += e.cssText;
          }}
          onDragEnd={(e) => {
            const position = handleDragEnd(e);
            position && setAnno({ ...anno, position });
          }}
          onResizeEnd={(e) => {
            const size = handleResizeEnd(e);
            setAnno({ ...anno, size });
          }}
          onRotateEnd={(e) => {
            const rotation = handleRotateEnd(e);
            setAnno({ ...anno, rotation });
          }}
        />
      ) : null}
    </>
  );
}
