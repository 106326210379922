import { useState, useEffect, useContext, useRef, Fragment } from 'react';
import { BarrierContext } from '../context/BarrierContext';
import { Rnd } from 'react-rnd';
import { Tooltip } from '@chakra-ui/react';

export default function DraggableElementItem({ item }) {
  const { setElement, cdft, setCdftFill } =
    useContext(BarrierContext);

  let cementSecondary = '/assets/well-barrier/patterns/cement-secondary.svg';
  let cementPrimary = '/assets/well-barrier/patterns/cement-primary.svg';
  let cementNone = '/assets/well-barrier/patterns/cement-none.svg';

  const handleDragStop = (e, data) => {
    setElement({
      ...item,
      position: {
        x: data.x,
        y: data.y,
      },
    });
  };

  const handleResizeStop = (e, direction, ref, delta, position) => {
    setElement({
      ...item,
      position: {
        x: position.x,
        y: position.y,
      },
      size: {
        width: ref.style.width,
        height: ref.style.height,
      },
    });
  };

  const setStroke = (barrier) => {
    switch (barrier) {
      case 'primary':
        return '#0000FF';
        break;
      case 'secondary':
        return '#FF0000';
        break;
      default:
        return '#000000';
    }
  };

  const switchElement = (name, quantity, barrier, status) => {
    switch (name) {
      case 'conductor casing':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CONDUCTOR CASING'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `2.5px solid ${setStroke(barrier)}`,
                    borderRight: `2.5px solid ${setStroke(barrier)}`,
                    // borderBottom: `1px solid ${setStroke(quantity, barrier)}`,
                  }}
                >
                  <div className='absolute bottom-[-1px] left-[-11px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.9838 0.513916L10.9838 5.99999H0.498825L10.9838 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                  <div className='absolute bottom-[-1px] right-[-11px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.515047 0.513916L0.515061 5.99999H11L0.515047 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>

                  <div className='absolute top-[0px] left-[-4px]'>
                    <svg
                      width='4'
                      height='14'
                      viewBox='0 0 4 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='4' height='14' fill='black' />
                    </svg>
                  </div>
                  <div className='absolute top-[0px] right-[-4px]'>
                    <svg
                      width='4'
                      height='14'
                      viewBox='0 0 4 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='4' height='14' fill='black' />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'production tubing (above dhsv)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='PRODUCTION TUBING (ABOVE DHSV)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `1px solid ${setStroke(barrier)}`,
                    borderRight: `1px solid ${setStroke(barrier)}`,
                    borderTop: `1px solid ${setStroke(barrier, status)}`,
                    borderBottom: `1px solid ${setStroke(barrier)}`,
                  }}
                />
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'production tubing (below dhsv)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='PRODUCTION TUBING (BELOW DHSV)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
                marginRight={6}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `1px solid ${setStroke(barrier)}`,
                    borderRight: `1px solid ${setStroke(barrier)}`,
                    borderTop: `1px solid ${setStroke(barrier)}`,
                    borderBottom: `1px solid ${setStroke(barrier)}`,
                  }}
                />
              </Tooltip>
            ) : null}
          </>
        );
        break;

        return (
          <>
            {quantity > 0 ? (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  borderLeft: `1px solid ${setStroke(quantity, barrier)}`,
                  borderRight: `1px solid ${setStroke(quantity, barrier)}`,
                  // borderBottom: `1px solid ${setStroke(quantity, barrier)}`,
                }}
              >
                <div className='absolute bottom-[-1px] left-[-11px]'>
                  <svg
                    width='269'
                    height='17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M207 16h62M224.837 1H269M0 16h61M0 1h44'
                      stroke='red'
                    />
                  </svg>
                </div>
                <div className='absolute bottom-[-1px] right-[-11px]'>
                  <svg
                    width='11'
                    height='6'
                    viewBox='0 0 11 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.515047 0.513916L0.515061 5.99999H11L0.515047 0.513916Z'
                      fill='black'
                    />
                  </svg>
                </div>
              </div>
            ) : null}
          </>
        );
        break;

      case 'downhole safety valve':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='DOWNHOLE SAFETY VALVE'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='41'
                  height='40'
                  viewBox='0 0 41 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g id='dhsv'>
                    <ellipse
                      id='Ellipse 1'
                      cx='13.9798'
                      cy='13.9798'
                      rx='13.9798'
                      ry='13.9798'
                      transform='matrix(0.714257 -0.699883 0.714257 0.699883 0.108658 19.5685)'
                      stroke={cdft ? '#000000' : setStroke(barrier)}
                      fill={cdft ? setCdftFill('DSV') : '#FFFFFF'}
                    />
                    <path
                      id='Vector 6'
                      d='M10.2548 9.94205L29.8686 29.1611'
                      stroke={cdft ? '#000000' : setStroke(barrier)}
                    />
                    <path
                      id='Vector 7'
                      d='M10.2548 29.5106L30.0641 10.0999'
                      stroke={cdft ? '#000000' : setStroke(barrier)}
                    />
                  </g>
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'dhsv control line':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='DHSV CONTROL LINE'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div className='flex w-full h-full'>
                  <div
                    style={{
                      width: '10px',
                      height: '100%',
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>
                  <div
                    style={{
                      width: '52px',
                      height: '100%',
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderTop: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'safety valve landing nipple':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='SAFETY VALVE LANDING NIPPLE'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='28'
                  height='24'
                  viewBox='0 0 28 24'
                  fill={cdft ? setCdftFill('safety valve landing nipple') : '#FFFFFF'}
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M26.9904 0.499996L14 23L1.00962 0.499999L26.9904 0.499996Z'
                    stroke={cdft ? '#000000' : setStroke(barrier)}
                  />
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'svln control line':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='SVLN CONTROL LINE'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div className='flex w-full h-full'>
                  <div
                    style={{
                      width: '52px',
                      height: '100%',
                      borderRight: `1px solid ${setStroke(barrier)}`,
                      borderTop: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>
                  <div
                    style={{
                      width: '10px',
                      height: '100%',
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'surface casing':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='SURFACE CASING'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `2px solid ${setStroke(barrier)}`,
                    borderRight: `2px solid ${setStroke(barrier)}`,
                    position: 'relative',
                  }}
                >
                  <div className='absolute top-[-4px] w-full h-1 bg-white'></div>

                  <div className='absolute bottom-[-1px] left-[-11px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.9838 0.513916L10.9838 5.99999H0.498825L10.9838 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                  <div className='absolute bottom-[-1px] right-[-10.5px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.515047 0.513916L0.515061 5.99999H11L0.515047 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'intermediate casing':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='INTERMEDIATE CASING'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `2px solid ${setStroke(barrier)}`,
                    borderRight: `2px solid ${setStroke(barrier)}`,
                    position: 'relative',
                  }}
                >
                  <div className='absolute bottom-[-1px] left-[-11px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.9838 0.513916L10.9838 5.99999H0.498825L10.9838 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                  <div className='absolute bottom-[-1px] right-[-10.5px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.515047 0.513916L0.515061 5.99999H11L0.515047 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'production casing (above packer)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='PRODUCTION CASING (BELOW PACKER)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
                marginRight={3}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `1.5px solid ${setStroke(barrier)}`,
                    borderRight: `1.5px solid ${setStroke(barrier)}`,
                    position: 'relative',
                    zIndex: 200,
                  }}
                ></div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'production casing (below packer)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='PRODUCTION CASING (ABOVE PACKER)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
                marginRight={3}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft: `1.5px solid ${setStroke(barrier)}`,
                    borderRight: `1.5px solid ${setStroke(barrier)}`,
                    borderBottom: `1px solid ${setStroke(barrier)}`,
                    position: 'relative',
                  }}
                >
                  <div className='absolute bottom-[-1px] left-[-11px]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.9838 0.513916L10.9838 5.99999H0.498825L10.9838 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                  <div className='absolute bottom-[-1px] right-[-11px] z-[777]'>
                    <svg
                      width='11'
                      height='6'
                      viewBox='0 0 11 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.515047 0.513916L0.515061 5.99999H11L0.515047 0.513916Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      // case 'tubing hanger':
      //   return (
      //     <>
      //       {quantity > 0 ? (
      //         <Tooltip
      //           label='TUBING HANGER'
      //           bg='gray.100'
      //           color='black'
      //           fontSize='8px'
      //           placement='left'
      //         >
      //           <div className='flex w-full h-full'>
      //             <svg
      //               width='244'
      //               height='20'
      //               viewBox='0 0 244 20'
      //               fill='none'
      //               xmlns='http://www.w3.org/2000/svg'
      //             >
      //               <ellipse
      //                 cx='6.98991'
      //                 cy='6.95051'
      //                 rx='6.98991'
      //                 ry='6.95051'
      //                 transform='matrix(0.714257 -0.699883 0.714257 0.699883 0 9.78419)'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //                 fill={cdft ? setFill(status) : '#FFFFFF'}
      //               />
      //               <path
      //                 d='M5.07263 4.97054L14.9778 14.6763'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //               />
      //               <path
      //                 d='M5.04449 14.7272L14.9492 5.02189'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //               />
      //               <ellipse
      //                 cx='6.98991'
      //                 cy='6.95051'
      //                 rx='6.98991'
      //                 ry='6.95051'
      //                 transform='matrix(0.714257 -0.699883 0.714257 0.699883 223.439 9.78419)'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //                 fill={cdft ? setFill(status) : '#FFFFFF'}
      //               />
      //               <path
      //                 d='M228.511 4.97054L238.416 14.6763'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //               />
      //               <path
      //                 d='M228.483 14.7272L238.388 5.02189'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //               />
      //               <path
      //                 d='M17 10L226.553 10.0713'
      //                 stroke={cdft ? '#000000' : setStroke(barrier)}
      //               />
      //             </svg>
      //           </div>
      //         </Tooltip>
      //       ) : null}
      //     </>
      //   );
      //   break;

      case 'cement (conductor casing)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CEMENT (CONDUCTOR CASING)'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>

                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                      transform: 'scaleX(-1)',
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'cement (surface casing)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CEMENT (SURFACE CASING)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>

                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                      transform: 'scaleX(-1)',
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'cement (intermediate casing)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CEMENT (INTERMEDIATE CASING)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>

                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                      transform: 'scaleX(-1)',
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;

      case 'cement (production casing)':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CEMENT (PRODUCTION CASING)'
                bg='gray.100'
                color='black'
                fontSize='8px'
                placement='left'
              >
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                  <div
                    style={{
                      width: '50%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                    }}
                  ></div>
                  <div
                    style={{
                      width: '50%',
                      height: '100%',
                      backgroundColor: 'white',
                      backgroundRepeat: 'repeat',
                      backgroundImage:
                        barrier === 'secondary'
                          ? 'url(' + cementSecondary + ')'
                          : barrier === 'primary'
                          ? 'url(' + cementPrimary + ')'
                          : 'url(' + cementNone + ')',
                      borderTop: `1px solid ${setStroke(barrier)}`,
                      borderBottom: `1px solid ${setStroke(barrier)}`,
                      borderLeft: `1px solid ${setStroke(barrier)}`,
                      borderRight: `1px solid ${setStroke(barrier)}`,
                      transform: 'scaleX(-1)',
                    }}
                  ></div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'gas lift mandrel':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='GAS LIFT MANDREL'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='6'
                  height='28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6 3.043V24.956L0 28V0l6 3.043Z'
                    fill={setStroke(barrier)}
                  />
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'sliding side door':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='SLIDING SIDE DOOR'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='36'
                  height='20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path stroke={setStroke(barrier)} d='M.5.5h35v19H.5z' />
                  <path
                    fill={setStroke(barrier)}
                    d='M30.861 0H36v20h-5.139zM.028 0h5.139v20H.028z'
                  />
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'production packer':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='PRODUCTION PACKER'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: setStroke(barrier),
                  }}
                ></div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'tubing plug':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='TUBING PLUG'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='29'
                  height='50'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g fill={setStroke(barrier)}>
                    <path d='M2.9 10a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v34.565a3 3 0 0 1-2.383 2.936l-8.905 1.87a3 3 0 0 1-1.224.002l-9.095-1.879A3 3 0 0 1 2.9 44.556V10Z' />
                    <path d='M0 14h29v8H0zM0 31h29v8H0zM11 0h8v7h-8z' />
                  </g>
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'bpv/twcv':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='BPV/TWCV'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <svg
                  width='18'
                  height='34'
                  viewBox='0 0 18 34'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15.9282 5L9 17L2.0718 5L15.9282 5Z'
                    stroke={setStroke(barrier)}
                  />
                  <path
                    d='M15.9282 29L9 17L2.0718 29L15.9282 29Z'
                    stroke={setStroke(barrier)}
                  />
                </svg>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'vr plug':
        return (
          <>
            <>
              {quantity > 0 ? (
                <>
                  <Tooltip
                    label='VR PLUG'
                    bg='gray.100'
                    color='black'
                    fontSize='8px'
                  >
                    <svg
                      width='33'
                      height='12'
                      viewBox='0 0 33 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M28 1.53206L16.3919 6L28 10.4679L28 1.53206Z'
                        fill='white'
                        stroke={setStroke(barrier)}
                      />
                      <path
                        d='M5 1.53206L16.6081 6L5 10.4679L5 1.53206Z'
                        fill='white'
                        stroke={setStroke(barrier)}
                      />
                    </svg>
                  </Tooltip>
                </>
              ) : null}
            </>
          </>
        );
        break;

      case 'caprock':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='CAPROCK'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '7px',
                      fontWeight: 'bolder',
                      color: setStroke(barrier),
                    }}
                  >
                    <div>CAPROCK</div>
                    <div>CAPROCK</div>
                  </div>
                  <div className='absolute top-0 left-0 w-full h-[1px]'>
                    <svg
                      width='301'
                      height='1'
                      viewBox='0 0 301 1'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line y1='0.5' x2='301' y2='0.5' stroke='black' />
                    </svg>
                  </div>
                  <div className='absolute bottom-0 left-0'>
                    <svg
                      width='301'
                      height='1'
                      viewBox='0 0 301 1'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line y1='0.5' x2='301' y2='0.5' stroke='black' />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'void area':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='VOID AREA'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div className='flex items-center w-full justify-between'>
                  <div
                    style={{
                      fontSize: '7px',
                      fontWeight: 'bolder',
                      color: setStroke(barrier),
                    }}
                  >
                    VOID AREA
                  </div>
                  <div
                    style={{
                      fontSize: '7px',
                      fontWeight: 'bolder',
                      color: setStroke(barrier),
                    }}
                  >
                    VOID AREA
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      case 'in-situ formation':
        return (
          <>
            {quantity > 0 ? (
              <Tooltip
                label='IN-SITU FORMATION'
                bg='gray.100'
                color='black'
                fontSize='8px'
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '7px',
                      fontWeight: 'bolder',
                      color: setStroke(barrier),
                    }}
                  >
                    <div>IN-SITU FORMATION</div>
                    <div>IN-SITU FORMATION</div>
                  </div>
                  <div className='absolute top-0 left-0 w-full h-[1px] '>
                    <svg
                      width='301'
                      height='1'
                      viewBox='0 0 301 1'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line y1='0.5' x2='301' y2='0.5' stroke='black' />
                    </svg>
                  </div>
                  <div className='absolute bottom-0 left-0'>
                    <svg
                      width='301'
                      height='1'
                      viewBox='0 0 301 1'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line y1='0.5' x2='301' y2='0.5' stroke='black' />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </>
        );
        break;
      // additional elements start

      case 'sea level':
        return (
          <>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <div className='absolute top-0 left-0'>
                <svg
                  width='301'
                  height='23'
                  viewBox='0 0 306 23'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M305.104 12.2991L302.797 18.7608H268.055L267 13.9144C267 13.9144 295.348 15.4567 305.104 12.2991Z'
                    fill='black'
                  />
                  <path
                    d='M297.194 14.7955L295.084 11.418L273.659 11.4915L271.549 14.869L297.194 14.7955Z'
                    fill='black'
                  />
                  <path
                    d='M278.9 3.08414C279.658 3.08414 279.756 6.49818 279.756 6.49818H278.043C278.043 6.49818 278.141 3.08414 278.9 3.08414Z'
                    fill='black'
                  />
                  <path
                    d='M287.437 6.20477L284.371 0H283.844L285.838 4.07522L285.162 6.93896L287.437 6.20477Z'
                    fill='black'
                  />
                  <path
                    d='M287.7 9.28846H277.482L275.966 11.5649H288.953L287.7 9.28846ZM276.757 6.20478H287.436L290.601 11.7853L272.363 13.5651L276.757 6.20478Z'
                    fill='black'
                  />
                  <path
                    d='M285.843 8.86253H286.652V12.2694H285.843V8.86253Z'
                    fill='black'
                  />
                  <path
                    d='M283.335 8.86253H284.145V12.2694H283.335V8.86253Z'
                    fill='black'
                  />
                  <path
                    d='M280.828 8.86253H281.638V12.2694H280.828V8.86253Z'
                    fill='black'
                  />
                  <path
                    d='M278.322 8.86253H279.131V12.2694H278.322V8.86253Z'
                    fill='black'
                  />
                  <path
                    d='M59 17.8571L59.8188 19.3192C60.6491 20.8018 62.8259 20.6648 63.4638 19.0898L63.6808 18.554C64.2458 17.1589 66.1874 17.0704 66.8771 18.4082V18.4082C67.5164 19.6485 69.2757 19.6874 69.9692 18.4766L70.1985 18.0763C70.9356 16.7893 72.8194 16.8746 73.4372 18.2229V18.2229C74.0405 19.5396 75.8643 19.6607 76.6364 18.4353L77.0287 17.8125C77.8502 16.5086 79.7511 16.5086 80.5726 17.8125L81.0035 18.4964C81.7356 19.6585 83.4765 19.5006 83.9876 18.2258V18.2258C84.5108 16.9207 86.3093 16.7956 87.0081 18.0158L87.1857 18.3258C87.9164 19.6016 89.7702 19.5607 90.4439 18.2538L90.5479 18.052C91.207 16.7734 93.0352 16.7734 93.6943 18.052L93.7773 18.2129C94.42 19.4598 96.2169 19.4182 96.8013 18.1429V18.1429C97.3856 16.8675 99.1825 16.8259 99.8253 18.0728L99.9514 18.3175C100.611 19.5969 102.426 19.637 103.141 18.388L103.242 18.2121C104.011 16.8683 105.978 16.9573 106.623 18.3652V18.3652C107.226 19.6799 109.017 19.8689 109.88 18.709L110.228 18.2421C111.281 16.8282 113.477 17.1208 114.123 18.761L114.442 19.5714'
                    stroke='black'
                  />
                  <path
                    d='M114 17.8571L114.819 19.3192C115.649 20.8018 117.826 20.6648 118.464 19.0898L118.681 18.554C119.246 17.1589 121.187 17.0704 121.877 18.4082V18.4082C122.516 19.6485 124.276 19.6874 124.969 18.4766L125.198 18.0763C125.936 16.7893 127.819 16.8746 128.437 18.2229V18.2229C129.04 19.5396 130.864 19.6607 131.636 18.4353L132.029 17.8125C132.85 16.5086 134.751 16.5086 135.573 17.8125L136.003 18.4964C136.736 19.6585 138.477 19.5006 138.988 18.2258V18.2258C139.511 16.9207 141.309 16.7956 142.008 18.0158L142.186 18.3258C142.916 19.6016 144.77 19.5607 145.444 18.2538L145.548 18.052C146.207 16.7734 148.035 16.7734 148.694 18.052L148.777 18.2129C149.42 19.4598 151.217 19.4182 151.801 18.1429V18.1429C152.386 16.8675 154.183 16.8259 154.825 18.0728L154.951 18.3175C155.611 19.5969 157.426 19.637 158.141 18.388L158.242 18.2121C159.011 16.8683 160.978 16.9573 161.623 18.3652V18.3652C162.226 19.6799 164.017 19.8689 164.88 18.709L165.228 18.2421C166.281 16.8282 168.477 17.1208 169.123 18.761L169.442 19.5714'
                    stroke='black'
                  />
                  <path
                    d='M169 17.8571L169.819 19.3192C170.649 20.8018 172.826 20.6648 173.464 19.0898L173.681 18.554C174.246 17.1589 176.187 17.0704 176.877 18.4082V18.4082C177.516 19.6485 179.276 19.6874 179.969 18.4766L180.198 18.0763C180.936 16.7893 182.819 16.8746 183.437 18.2229V18.2229C184.04 19.5396 185.864 19.6607 186.636 18.4353L187.029 17.8125C187.85 16.5086 189.751 16.5086 190.573 17.8125L191.003 18.4964C191.736 19.6585 193.477 19.5006 193.988 18.2258V18.2258C194.511 16.9207 196.309 16.7956 197.008 18.0158L197.186 18.3258C197.916 19.6016 199.77 19.5607 200.444 18.2538L200.548 18.052C201.207 16.7734 203.035 16.7734 203.694 18.052L203.777 18.2129C204.42 19.4598 206.217 19.4182 206.801 18.1429V18.1429C207.386 16.8675 209.183 16.8259 209.825 18.0728L209.951 18.3175C210.611 19.5969 212.426 19.637 213.141 18.388L213.242 18.2121C214.011 16.8683 215.978 16.9573 216.623 18.3652V18.3652C217.226 19.6799 219.017 19.8689 219.88 18.709L220.228 18.2421C221.281 16.8282 223.477 17.1208 224.123 18.761L224.442 19.5714'
                    stroke='black'
                  />
                  <path
                    d='M223 17.8571L223.819 19.3192C224.649 20.8018 226.826 20.6648 227.464 19.0898L227.681 18.554C228.246 17.1589 230.187 17.0704 230.877 18.4082V18.4082C231.516 19.6485 233.276 19.6874 233.969 18.4766L234.198 18.0763C234.936 16.7893 236.819 16.8746 237.437 18.2229V18.2229C238.04 19.5396 239.864 19.6607 240.636 18.4353L241.029 17.8125C241.85 16.5086 243.751 16.5086 244.573 17.8125L245.003 18.4964C245.736 19.6585 247.477 19.5006 247.988 18.2258V18.2258C248.511 16.9207 250.309 16.7956 251.008 18.0158L251.186 18.3258C251.916 19.6016 253.77 19.5607 254.444 18.2538L254.548 18.052C255.207 16.7734 257.035 16.7734 257.694 18.052L257.777 18.2129C258.42 19.4598 260.217 19.4182 260.801 18.1429V18.1429C261.386 16.8675 263.183 16.8259 263.825 18.0728L263.951 18.3175C264.611 19.5969 266.426 19.637 267.141 18.388L267.242 18.2121C268.011 16.8683 269.978 16.9573 270.623 18.3652V18.3652C271.226 19.6799 273.017 19.8689 273.88 18.709L274.228 18.2421C275.281 16.8282 277.477 17.1208 278.123 18.761L278.442 19.5714'
                    stroke='black'
                  />
                  <path
                    d='M277.5 18L277.942 18.5814C278.946 19.901 280.954 19.8305 281.863 18.4437L282.25 17.8533C283.085 16.5786 284.985 16.6778 285.683 18.0325L285.747 18.1566C286.443 19.5053 288.356 19.5476 289.11 18.2309L289.198 18.0763C289.936 16.7893 291.819 16.8746 292.437 18.2229V18.2229C293.04 19.5396 294.864 19.6607 295.636 18.4353L295.825 18.1363C296.676 16.7851 298.686 16.915 299.356 18.3646L299.42 18.502C300.198 20.1833 302.614 20.1036 303.279 18.3747L304 16.5'
                    stroke='black'
                  />
                  <path
                    d='M1 17.8571L1.87381 19.3381C2.76958 20.8563 5.01125 20.7107 5.70308 19.0894L5.92578 18.5675C6.5414 17.1248 8.55059 17.0301 9.29918 18.4085V18.4085C9.99095 19.6823 11.8045 19.7235 12.5534 18.4824L12.806 18.0638C13.6012 16.746 15.5413 16.8365 16.2104 18.2225V18.2225C16.8632 19.5749 18.7397 19.7029 19.5702 18.4518L19.9955 17.8111C20.8814 16.4764 22.8414 16.4764 23.7274 17.8111L24.1963 18.5177C24.9831 19.703 26.7729 19.5358 27.3265 18.2252V18.2252C27.8937 16.8824 29.7445 16.7496 30.4977 17.9977L30.6961 18.3265C31.4866 19.6366 33.401 19.5931 34.1312 18.2485L34.238 18.0519C34.9519 16.7373 36.839 16.7373 37.5529 18.0519L37.6436 18.2189C38.3392 19.4998 40.1927 19.4555 40.8263 18.1429V18.1429C41.46 16.8302 43.3134 16.7859 44.0091 18.0668L44.1452 18.3175C44.8589 19.6316 46.7297 19.6741 47.5023 18.3938L47.6204 18.198C48.4505 16.8225 50.4756 16.9169 51.174 18.3637V18.3637C51.8247 19.7117 53.6634 19.9105 54.5872 18.7328L54.9713 18.2432C56.1094 16.7924 58.3884 17.1043 59.095 18.8073L59.412 19.5714'
                    stroke='black'
                  />
                </svg>
              </div>
              <div className='absolute bottom-0 left-0'>
                <svg
                  width='301'
                  height='13'
                  viewBox='0 0 301 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M275.087 6.06311C276.309 4.01296 280.196 1.57028 283.75 2.00645C285.527 0.479742 286.453 0.0869598 286.453 0.0869598C286.453 0.0869598 287.971 1.83188 287.711 3.40223C287.045 3.79501 285.897 3.27156 285.897 3.27156C285.897 3.27156 287.489 4.3621 288.563 4.23117C289.637 4.10024 292.117 0.436099 292.562 1.74459C292.599 3.18427 292.08 4.75462 292.339 5.1035C292.599 5.45264 293.857 7.11001 292.672 7.45915C291.488 7.80829 289.415 6.10675 288.563 6.54318C287.711 6.97934 287.415 7.28458 287.415 7.28458C287.415 7.28458 288.526 7.02299 288.933 6.97935C289.341 6.9357 288.563 11.9956 284.676 8.46241C284.268 8.50631 283.75 8.54969 283.75 8.54969C283.75 8.54969 285.12 9.42202 284.75 9.94547C284.38 10.4689 283.565 11.2106 282.195 8.81128C279.233 8.98585 274.284 7.41052 275.087 6.06311Z'
                    fill='black'
                  />
                  <line y1='12.5' x2='301' y2='12.5' stroke='black' />
                </svg>
              </div>
            </div>
          </>
        );
        break;

      case 'perforations':
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ position: 'absolute', left: -28, top: 0 }}>
              <svg
                width='38'
                height='24'
                viewBox='0 0 38 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M-1.74846e-07 3.09894L28.0271 0.415228L28.0271 5.78264L-1.74846e-07 3.09894Z'
                  fill='black'
                />
                <path
                  d='M-1.74846e-07 11.6208L28.0271 8.93711L28.0271 14.3045L-1.74846e-07 11.6208Z'
                  fill='black'
                />
                <path
                  d='M-1.74846e-07 20.1427L28.0271 17.459L28.0271 22.8264L-1.74846e-07 20.1427Z'
                  fill='black'
                />
              </svg>
            </div>
            <div style={{ position: 'absolute', right: -28, top: 0 }}>
              <svg
                width='38'
                height='24'
                viewBox='0 0 38 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.3695 3.78082L9.3424 1.09711L9.3424 6.46453L37.3695 3.78082Z'
                  fill='black'
                />
                <path
                  d='M37.3695 12.3027L9.3424 9.61899L9.3424 14.9864L37.3695 12.3027Z'
                  fill='black'
                />
                <path
                  d='M37.3695 20.8246L9.3424 18.1409L9.3424 23.5083L37.3695 20.8246Z'
                  fill='black'
                />
              </svg>
            </div>
            {/* <svg
              width='100%'
              height='100%'
              viewBox='0 0 113 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M113 3L90.5 5.59808L90.5 0.401923L113 3Z' fill='black' />
              <path
                d='M1.31134e-07 3L22.5 5.59808L22.5 0.401923L1.31134e-07 3Z'
                fill='black'
              />
              <path
                d='M113 12L90.5 14.5981L90.5 9.40192L113 12Z'
                fill='black'
              />
              <path
                d='M1.31134e-07 12L22.5 14.5981L22.5 9.40192L1.31134e-07 12Z'
                fill='black'
              />
              <path
                d='M113 21L90.5 23.5981L90.5 18.4019L113 21Z'
                fill='black'
              />
              <path
                d='M1.31134e-07 21L22.5 23.5981L22.5 18.4019L1.31134e-07 21Z'
                fill='black'
              />
            </svg> */}
          </div>
        );
      // case 'perforations':
      //   return (
      //     <>
      //       <svg
      //         width='113'
      //         height='24'
      //         viewBox='0 0 113 24'
      //         fill='none'
      //         xmlns='http://www.w3.org/2000/svg'
      //       >
      //         <path d='M113 3L90.5 5.59808L90.5 0.401923L113 3Z' fill='black' />
      //         <path
      //           d='M1.31134e-07 3L22.5 5.59808L22.5 0.401923L1.31134e-07 3Z'
      //           fill='black'
      //         />
      //         <path
      //           d='M113 12L90.5 14.5981L90.5 9.40192L113 12Z'
      //           fill='black'
      //         />
      //         <path
      //           d='M1.31134e-07 12L22.5 14.5981L22.5 9.40192L1.31134e-07 12Z'
      //           fill='black'
      //         />
      //         <path
      //           d='M113 21L90.5 23.5981L90.5 18.4019L113 21Z'
      //           fill='black'
      //         />
      //         <path
      //           d='M1.31134e-07 21L22.5 23.5981L22.5 18.4019L1.31134e-07 21Z'
      //           fill='black'
      //         />
      //       </svg>
      //     </>
      //   );
      //   break;

      default:
        return null;
    }
  };

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: item?.zIndex,
  };

  return (
    <Rnd
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      style={style}
      dragAxis={item?.dragAxis}
      disableDragging={item?.isLocked}
      enableResizing={item?.isResizable}
      bounds='parent'
      default={{
        x: item?.position?.x,
        y: item?.position?.y,
        width: item?.size?.width,
        height: item?.size?.height,
      }}
      resizeGrid={[1, 1]}
      dragGrid={[1, 1]}
    >
      {switchElement(item?.name, item?.quantity, item?.barrier, item?.status)}
    </Rnd>
  );
}
