export const annotationMenu = [
  {
    id: 0,
    type: 'text',
    imageURL: '/assets/well-barrier/icons/text.svg',
  },
  {
    id: 1,
    type: 'line',
    imageURL: '/assets/well-barrier/icons/solid-line.svg',
  },
  {
    id: 2,
    type: 'arrow',
    imageURL: '/assets/well-barrier/icons/arrow.svg',
  },

  {
    id: 3,
    type: 'rectangle',
    imageURL: '/assets/well-barrier/icons/rectangle.svg',
  },
  {
    id: 4,
    type: 'circle',
    imageURL: '/assets/well-barrier/icons/circle.svg',
  },
  {
    id: 5,
    type: 'ellipse',
    imageURL: '/assets/well-barrier/icons/ellipse.svg',
  },
  {
    id: 6,
    type: 'triangle',
    imageURL: '/assets/well-barrier/icons/triangle.svg',
  },
];
