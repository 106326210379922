import { useContext, useState } from 'react';
import { BarrierContext } from '../context/BarrierContext';
import AnnotationItem from './AnnotationItem';
import { useKeyPress } from 'ahooks';
import { nanoid } from 'nanoid';

export default function AnnotationList() {
  const { anno, setAnno, config, setConfig } = useContext(BarrierContext);
  const [copy, setCopy] = useState(null);

  const handleCopyAnno = () => {
    if (anno) {
      const newAnno = {
        ...anno,
        id: nanoid(),
        position: {
          x: anno?.position?.x + 25,
          y: anno?.position?.y + 25,
        },
      };
      setConfig((prev) => ({
        ...prev,
        annotations: [...config?.annotations, newAnno],
      }));
    }
  };

  const handleDeleteAnno = () => {
    if (anno?.type === 'text') return;
    if (anno) {
      const updated = config?.annotations?.filter(
        (item) => item.id !== anno?.id
      );

      setConfig((prev) => ({
        ...prev,
        annotations: updated,
      }));
      setAnno(null);
    }
  };

  useKeyPress([37], () => {
    setAnno((prev) => ({
      ...prev,
      ...anno,
      position: {
        x: anno?.position?.x - 5,
        y: anno?.position?.y,
      },
    }));
  });

  useKeyPress([38], () => {
    setAnno((prev) => ({
      ...prev,
      ...anno,
      position: {
        x: anno?.position?.x,
        y: anno?.position?.y - 5,
      },
    }));
  });

  useKeyPress([39], () => {
    setAnno((prev) => ({
      ...prev,
      ...anno,
      position: {
        x: anno?.position?.x + 5,
        y: anno?.position?.y,
      },
    }));
  });
  useKeyPress([40], () => {
    setAnno((prev) => ({
      ...prev,
      ...anno,
      position: {
        x: anno?.position?.x,
        y: anno?.position?.y + 5,
      },
    }));
  });

  // copy for windows

  useKeyPress(['ctrl.c'], () => {
    setCopy(true);
  });
  useKeyPress(['ctrl.v'], () => {
    if (anno && copy) {
      handleCopyAnno();
    }
    setCopy(false);
  });

  // copy for macOS

  useKeyPress(['meta.c'], () => {
    setCopy(true);
  });
  useKeyPress(['meta.v'], () => {
    if (anno && copy) {
      handleCopyAnno();
    }
    setCopy(false);
  });

  // delete anno
  useKeyPress([8], () => {
    if (anno && anno?.type !== 'text') {
      handleDeleteAnno();
    }
  });

  return (
    <>
      {config?.annotations?.map((item) => {
        return (
          <AnnotationItem
            item={item}
            key={item?.id}
            onClick={(prev) => setAnno({ ...prev, ...item })}
          />
        );
      })}
    </>
  );
}
