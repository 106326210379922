import { useState, useEffect, useContext, useRef } from 'react';
import { BarrierContext } from '../context/BarrierContext';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  SimpleGrid,
  Center,
  Image,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import {
  initialConfigFourCasings,
  initialConfigThreeCasings,
  initialConfigTwoCasings,
} from '../data/initialConfig';

export default function CasingConfig() {
  const { setComponent, config, setConfig } = useContext(BarrierContext);
  const [casingConfig, setCasingConfig] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const switchInitialConfig = (casingConfig) => {
    switch (casingConfig) {
      case '4 casings':
        setConfig(initialConfigFourCasings);
        break;
      case '3 casings':
        setConfig(initialConfigThreeCasings);
        break;
      case '2 casings':
        setConfig(initialConfigTwoCasings);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    switchInitialConfig(casingConfig);
  }, [casingConfig]);

  return (
    <>
      <Modal isOpen={true} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={16}>Casing Configuration</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setComponent(null);
              setCasingConfig('');
            }}
          />
          <ModalBody>
            <Text fontSize={14}>Click to select</Text>
            <SimpleGrid columns={3} spacing={10}>
              <Center
                height='100%'
                width='100%'
                borderWidth='1px'
                borderColor={casingConfig === '4 casings' ? 'blue.500' : null}
                padding={3}
                borderRadius={5}
                cursor={'pointer'}
                _hover={{ borderColor: 'blue.500' }}
                onClick={() => setCasingConfig('4 casings')}
              >
                <Image src='/assets/images/casing-config/4-casings.svg' draggable={false} />
              </Center>
              <Center
                height='100%'
                width='100%'
                borderWidth='1px'
                borderColor={casingConfig === '3 casings' ? 'blue.500' : null}
                padding={3}
                borderRadius={5}
                cursor={'pointer'}
                _hover={{ borderColor: 'blue.500' }}
                onClick={() => setCasingConfig('3 casings')}
              >
                <Image src='/assets/images/casing-config/3-casings.svg' draggable={false} />
              </Center>
              <Center
                height='100%'
                width='100%'
                borderWidth='1px'
                borderColor={casingConfig === '2 casings' ? 'blue.500' : null}
                padding={3}
                borderRadius={5}
                cursor={'pointer'}
                _hover={{ borderColor: 'blue.500' }}
                onClick={() => setCasingConfig('2 casings')}
              >
                <Image src='/assets/images/casing-config/2-casings.svg' draggable={false} />
              </Center>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter justifyContent='space-between'>
            <Text fontSize={14}> {`Selected: ${casingConfig}`} </Text>
            <Button
              colorScheme='blue'
              size='sm'
              w='80px'
              onClick={() => {
                if (config) {
                  setComponent('diagram');
                }
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
